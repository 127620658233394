import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/redux/store';
import { IFilter } from 'features/types/filters';
import { Paginated } from 'features/types/paginatedQuery';
import {IDashboardData, IProgressData, IProgressDataDetails, IStructure, IUser} from 'features/User/UserSlice';
import { baseBackendUrl } from 'utils/config';

export const UsersApi = createApi({
  reducerPath: 'users',
  tagTypes: ['Users', 'User'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllForTransactions: builder.query<IUser[], void>({
      query: () => 'users/all-for-transactions',
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: 'Users', id })) : [],
      keepUnusedDataFor: 0,
    }),
    getProgressData: builder.query<IProgressData, {id: number, period: any}>({
      query: ({id, period}) => `/mlmProgress/data/${id}?year=${period.year}&month=${period.month}`,
      keepUnusedDataFor: 0,
    }),
    getDashboardData: builder.query<IDashboardData, {id: number}>({
      query: ({id}) => `/mlmProgress/dashboard/${id}`,
      keepUnusedDataFor: 0,
    }),
    getDetailsProgressData: builder.query<IProgressDataDetails[], {id: number, period: any}>({
      query: ({id, period}) => `/mlmProgress/details/${id}?year=${period.year}&month=${period.month}`,
      keepUnusedDataFor: 0,
    }),
    getAllUsers: builder.query<Paginated<IUser>, void>({
      query: () => 'users',
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'Users', id })) : [],
      keepUnusedDataFor: 0,
    }),
    getUserById: builder.query<IUser, number>({
      query: (id) => `users/${id}`,
      providesTags: (result) => (result ? [{ type: 'Users', id: result.id }] : []),
    }),
    getStructureById: builder.query<IStructure<IUser>, number>({
      query: (id) => {
        return `users/structure/${id}`;
      },
      providesTags: (result) => (result ? [{ type: 'Users', id: result.id }] : []),
    }),
    getAllStructures: builder.query<IStructure<IUser>[], void>({
      query: () => 'users/structure',
      providesTags: (result) =>
        result ? result.map((element) => ({ type: 'Users', id: element.id })) : [],
    }),
    getFiltered: builder.query<Paginated<IUser>, IFilter>({
      query: (filters) =>
        `users/regular?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users' as const, id })),
              { type: 'Users', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Users', id: 'PARTIAL-LIST' }],

      keepUnusedDataFor: 0,
    }),
    getTurnoverFiltered: builder.query<Paginated<IUser>, IFilter>({
      query: (filters) =>
        `users/turnover?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`
    }),
    getFilteredAdmins: builder.query<Paginated<IUser>, IFilter>({
      query: (filters) =>
        `users/admins?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users' as const, id })),
              { type: 'Users', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Users', id: 'PARTIAL-LIST' }],
      keepUnusedDataFor: 0,
    }),
    addUser: builder.mutation<IUser, void>({
      query(body) {
        return {
          url: `users`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'User', body },
          { type: 'Users', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    editUser: builder.mutation<IUser, Pick<IUser, 'id'>>({
      query(body) {
        const { id, ...rest } = body;
        return {
          url: `users/${id}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: ['User'],
    }),
    deleteOneUser: builder.mutation<IUser, number>({
      query(id) {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'User', id },
          { type: 'Users', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteManyUsers: builder.mutation<IUser[], number[]>({
      query(body) {
        return {
          url: `users/`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'User', body },
          { type: 'Users', body: 'PARTIAL-LIST' },
        ];
      },
    }),
  }),
});

export default UsersApi;
