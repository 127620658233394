export const loadStateFromLocalStorage = (key: string) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveStateToLocalStorage = (key: string, obj: object) => {
  try {
    const serializedState = JSON.stringify(obj);
    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const clearLocalStorage = (key?: string) => {
  try {
    if (key) {
      localStorage.removeItem(key);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('cart');
      localStorage.removeItem('orderDetails');
    }
  } catch {
    // ignore write errors
  }
};
