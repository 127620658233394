import { createSlice } from '@reduxjs/toolkit';

interface MenuState {
  menuStateDesktop: boolean;
  menuStateMobile: boolean;
  navbarHeight: number;
}

const initialState = {
  menuStateDesktop: true,
  menuStateMobile: false,
  navbarHeight: null,
} as MenuState;

export const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    menuToggleDesktop: (state) => {
      state.menuStateDesktop = !state.menuStateDesktop;
    },
    menuToggleMobile: (state) => {
      state.menuStateMobile = !state.menuStateMobile;
    },
    saveNavbarHeight: (state, action) => {
      const navbarHeight = action.payload;
      state.navbarHeight = navbarHeight;
    },
  },
});

export const { menuToggleDesktop, menuToggleMobile, saveNavbarHeight } =
  menuSlice.actions;
export const menuSelector = (state) => state.menuSlice;
