import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilter } from '../types/filters';
import { ISearch } from '../types/search';
import { Paginated } from '../types/paginatedQuery';
import { baseBackendUrl } from 'utils/config';
import { RootState } from 'app/redux/store';
import { IMlmRole } from 'features/Roles/rolesSlice';

export interface IFile {
  id: number;
  originalName: string;
  name: string;
  alt?: string;
  url: string;
  path: string;
  mimetype: string;
  size: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDownloadableFile {
  id: number;
  name: string;
  description: string;
  category: string;
  active: boolean;
  roles: IMlmRole[];
  file: IFile;
  end: Date;
  start: Date;
}

export const filesApi = createApi({
  reducerPath: 'files',
  tagTypes: ['Files', 'File'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllFiles: builder.query<Paginated<IDownloadableFile>, void>({
      query: () => 'files',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Files' as const, id })),
              { type: 'Files', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Files', id: 'PARTIAL-LIST' }],
    }),

    getFiltered: builder.query<Paginated<IDownloadableFile>, IFilter>({
      query: (filters) =>
        `files?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'File' as const, id })),
              { type: 'File', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'File', id: 'PARTIAL-LIST' }],
    }),
    getFilteredForUser: builder.query<Paginated<IDownloadableFile>, IFilter>({
      query: (filters) =>
        `files/user?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'File' as const, id })),
              { type: 'File', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'File', id: 'PARTIAL-LIST' }],
    }),
    findFile: builder.query<Paginated<IDownloadableFile>, ISearch>({
      query: (search) =>
        `files/search?order=ASC&offset=0&limit=10&search[phrase][value]=${
          search.phrase
        }&search[categories][value]=[${[...search.categories]}]`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Files' as const, id })),
              { type: 'Files', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Files', id: 'PARTIAL-LIST' }],
    }),
    getFile: builder.query<IDownloadableFile, number>({
      query: (id) => `files/${id}`,
      providesTags: (result) => (result ? [{ type: 'File', id: result.id }] : []),
    }),
    downloadFile: builder.query<{ content: string; name: string }, number>({
      query: (id) => `files/user/${id}`,
    }),
    addFile: builder.mutation<any, any>({
      query(body) {
        return {
          url: `files`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'File', body },
          { type: 'Files', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    editFile: builder.mutation<IDownloadableFile, any>({
      query({ id, formData }) {
        return {
          url: `files/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'File', body },
          { type: 'Files', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteManyFiles: builder.mutation<IDownloadableFile[], number[]>({
      query(body) {
        return {
          url: `files/`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'File', body },
          { type: 'Files', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteOneFile: builder.mutation<IDownloadableFile, number>({
      query(id) {
        return {
          url: `files/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'File', id },
          { type: 'Files', id: 'PARTIAL-LIST' },
        ];
      },
    }),
  }),
});

export default filesApi;
