import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/redux/store';
import { baseBackendUrl } from 'utils/config';
import {INews} from "./NewsSlice";
import {IUser} from "../User/UserSlice";

export const NewsApi = createApi({
  reducerPath: 'news',
  tagTypes: ['News'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    get: builder.query<INews, void>({
      query: (id) => `news/${id}`,
      keepUnusedDataFor: 0,
    }),
    all: builder.query<INews[], void>({
      query: () => `news/all`,
      keepUnusedDataFor: 0,
    }),
    findAllForUser: builder.query<INews[], Pick<IUser, 'id'>>({
      query(body) {
        const { id } = body;
        return {
          url: `news/for-user/${id}`,
          method: 'GET'
        };
      },
      keepUnusedDataFor: 0,
    }),
    add: builder.mutation<INews, INews>({
      query(body) {
        return {
          url: `news`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['News']
    }),
    edit: builder.mutation<INews, Pick<INews, 'id'>>({
      query(body) {
        const { id, ...rest } = body;
        return {
          url: `news/${id}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: ['News']
    }),
    delete: builder.mutation<INews, INews>({
      query(id) {
        return {
          url: `news/${id}`,
          method: 'DELETE',
        };
      },
    })
  }),
});

export default NewsApi;
