import PercentageField from 'components/PercentageField';
import { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
export default function DiscountOrder({ index }) {
  const { control, unregister, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.value`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={49}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PercentageField
                  field={field}
                  label={'Wartość'}
                  error={errors?.rewards?.[index]?.value}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.value?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
