import {
  BillingStatus,
  DeliveryStatus,
  OrderStatus,
  PaymentDocument,
  PaymentStatus,
  PaymentType,
  ShippingType,
  SubiektStatus,
} from 'features/Orders/orderDetailsSlice';
import { ProductType } from 'features/Products/productsSlice';
import { UserRole } from 'features/User/UserSlice';
import {
  VirtualWalletTransactionStatus,
  VirtualWalletTransactionType,
} from 'features/VirtualWalletTransactions/virtualWalletTransactionsSlice';

const orderStatusName = new Map();
orderStatusName.set(OrderStatus.NEW, 'Nowy');
orderStatusName.set(OrderStatus.READY_FOR_PACKING, 'Do realizacji');
orderStatusName.set(OrderStatus.INVOICED, 'Zafakturowane');
orderStatusName.set(OrderStatus.PACKED, 'Spakowane');
orderStatusName.set(OrderStatus.SENT, 'Wysłane');
orderStatusName.set(OrderStatus.CANCELED_BEFORE_INVOICE, 'Anulowane');
orderStatusName.set(OrderStatus.CANCELED_AFTER_INVOICE, 'Korekta');
orderStatusName.set(OrderStatus.RETURNED, 'Zwrot');
orderStatusName.set('CANCELED', 'Anulowane');

const deliveryStatusName = new Map();
deliveryStatusName.set(DeliveryStatus.DPD_ERROR, 'Błąd DPD');
deliveryStatusName.set(DeliveryStatus.NOT_SENT_TO_DPD, 'Przesyłka nie utworzona');
deliveryStatusName.set(DeliveryStatus.PACKAGE_CREATED, 'Przesyłka utworzona');

const paymentStatusName = new Map();
paymentStatusName.set(PaymentStatus.CANCELED, 'Anulowana');
paymentStatusName.set(PaymentStatus.CONFIRMATION_FAILED, 'Nieudane potwierdzenie');
paymentStatusName.set(PaymentStatus.CONFIRMED, 'Potwierdzona');
paymentStatusName.set(PaymentStatus.NOTIFICATION_FAILED, 'Nieudana notyfikacja');
paymentStatusName.set(PaymentStatus.ORDER_CREATED, 'Rozpoczęta');
paymentStatusName.set(PaymentStatus.REGISTERED, 'Zarejestrowana');
paymentStatusName.set(PaymentStatus.REGISTRATION_FAILED, 'Nieudana rejestracja');

const subiektStatusName = new Map();
subiektStatusName.set(SubiektStatus.CRITICAL_ERROR, 'Błąd krytyczny');
subiektStatusName.set(SubiektStatus.DISABLED, 'Wyłączony');
subiektStatusName.set(SubiektStatus.IN_SYNC, 'Zsynchronizowany');
subiektStatusName.set(SubiektStatus.NOT_IN_SYNC, 'Nie zsynchronizowany');
subiektStatusName.set(SubiektStatus.PRODUCT_NOT_FOUND, 'Produkt nie znaleziono');
subiektStatusName.set(SubiektStatus.PRODUCT_OUT_OF_STOCK, 'Brak produktu w magazynie');

const paymentDocumentName = new Map();
paymentDocumentName.set(PaymentDocument.INVOICE, 'Faktura');
paymentDocumentName.set(PaymentDocument.RECEIPT, 'Paragon');

const shippingTypeName = new Map();
shippingTypeName.set(ShippingType.DPD, 'DPD');
shippingTypeName.set(ShippingType.INPOST, 'INPOST');
shippingTypeName.set(ShippingType.LOCAL_PICKUP, 'Odbiór osobisty');

const paymentTypeName = new Map();
paymentTypeName.set(PaymentType.CASH, 'Gotówka');
paymentTypeName.set(PaymentType.P24, 'Przelewy24');
paymentTypeName.set(PaymentType.COD, 'Pobranie');
paymentTypeName.set(PaymentType.BANK, 'Przelew tradycyjny');

const userRoleName = new Map();
userRoleName.set(UserRole.ADMIN, 'Admin');
userRoleName.set(UserRole.USER, 'Użytkownik');

const csvExportBillingsFieldsNames = new Map();
csvExportBillingsFieldsNames.set('id', 'ID');
csvExportBillingsFieldsNames.set('status', 'Status');
csvExportBillingsFieldsNames.set('period', 'Okres');
csvExportBillingsFieldsNames.set('user.firstName', 'Imię');
csvExportBillingsFieldsNames.set('user.lastName', 'Nazwisko');
csvExportBillingsFieldsNames.set('user.externalId', 'ID Użytkownika');
csvExportBillingsFieldsNames.set('mlmRole.id', 'Rola');
csvExportBillingsFieldsNames.set('usersInFirstLine', 'Liczba osób bezpośrednio zrekrutowanych');
csvExportBillingsFieldsNames.set('selfTurnover', 'Obrót katalogowy własny');
csvExportBillingsFieldsNames.set('groupTurnover', 'Obrót katalogowy grupy');
csvExportBillingsFieldsNames.set('baseProvisionTurnover', 'Podstawa do naliczania prowizji obrót katalogowy własny i grupy');
csvExportBillingsFieldsNames.set('baseProvisionPercentage', '% prowizji');
csvExportBillingsFieldsNames.set('baseProvisionAmount', 'Kwota prowizji');
csvExportBillingsFieldsNames.set('activeProvisionTurnover', 'Podstawa do naliczenia prowizji - obrót katalogowy własny i grupy');
csvExportBillingsFieldsNames.set('newUsersInFirstLine', 'Jedna rekrutacja w danym miesiącu');
csvExportBillingsFieldsNames.set('turnoverCondition', 'Obrót katalogowy własny i grupy > 7000 PLN');
csvExportBillingsFieldsNames.set('activeProvisionPercentage', 'Procent aktywnej prowizji');
csvExportBillingsFieldsNames.set('activeProvisionAmount', 'Należna prowizja w zależności od aktywności');
csvExportBillingsFieldsNames.set('groupProvisionTurnover', 'Podstawa do naliczenia - obrót katalogowy wypromowanych grup');
csvExportBillingsFieldsNames.set('groupProvisionPercentage', '% prowizji');
csvExportBillingsFieldsNames.set('groupProvisionAmount', 'Kwota prowizji');
csvExportBillingsFieldsNames.set('allProvision', 'Kwota prowizji');

const csvExportVoucherFieldsNames = new Map();
csvExportVoucherFieldsNames.set('id', 'ID');
csvExportVoucherFieldsNames.set('code', 'Kod');
csvExportVoucherFieldsNames.set('usedTime', 'Liczba wykorzystań');
csvExportVoucherFieldsNames.set('maxUsedTime', 'Limit użyć');
csvExportVoucherFieldsNames.set('type', 'Typ');
csvExportVoucherFieldsNames.set('value', 'Wartość');
csvExportVoucherFieldsNames.set('description', 'Opis');
csvExportVoucherFieldsNames.set('order', 'Zamówienie');
csvExportVoucherFieldsNames.set('createdAt', 'Data utworzenia');
csvExportVoucherFieldsNames.set('expiredAt', 'Data wygaśnięcia');
csvExportVoucherFieldsNames.set('user.externalId', 'ID użytkownika');
csvExportVoucherFieldsNames.set('user.firstName', 'Imię');
csvExportVoucherFieldsNames.set('user.lastName', 'Nazwisko');

const csvExportFieldsNames = new Map();
csvExportFieldsNames.set('dbId', 'ID zamówienia');
csvExportFieldsNames.set('id', 'ID zamówienia');
csvExportFieldsNames.set('user.externalId', 'ID użytkownika');
csvExportFieldsNames.set('user.name', 'Imię i nazwisko');
csvExportFieldsNames.set('address', 'Address');
csvExportFieldsNames.set('numbers.standardProductsCatalogValue', 'Wartość katalogowa');
csvExportFieldsNames.set('numbers.total', 'Wartość do zapłaty');
csvExportFieldsNames.set('paymentData.paymentStatus', 'Status płatności');
csvExportFieldsNames.set('paymentData.paymentType', 'Sposób płatności');
csvExportFieldsNames.set('details.shippingType', 'Sposób dostawy');
csvExportFieldsNames.set('deliveryData.status', 'Status dostawy');
csvExportFieldsNames.set('details.paymentDocument', 'Dokument sprzedaży');
csvExportFieldsNames.set('documents.orderDocumentNumber', 'Nr zamówienia Subiekt');
csvExportFieldsNames.set('documents.paymentDocumentNumber', 'Nr faktury/paragonu');
csvExportFieldsNames.set('documents.returnDocumentNumber', 'Nr korekty/zwrotu');
csvExportFieldsNames.set('subiektStatus', 'Status Subiekt');
csvExportFieldsNames.set('total', 'Kwota do zapłaty brutto');
csvExportFieldsNames.set('status', 'Status zamówienia');
csvExportFieldsNames.set('codTotal', 'Kwota pobrania');
csvExportFieldsNames.set('catalogTotal', 'Suma katalogowa');
csvExportFieldsNames.set('netTotal', 'Kwota do zapłaty netto');
csvExportFieldsNames.set('externalOrderId', 'Nr zamówienia');
csvExportFieldsNames.set('placedAt', 'Data złożenia');
csvExportFieldsNames.set('deliveryStatus', 'Status dostawy');
csvExportFieldsNames.set('paymentStatus', 'Status płatności');
csvExportFieldsNames.set('paymentType', 'Forma płatności');
csvExportFieldsNames.set('paymentDocument', 'Dokument sprzedaży');
csvExportFieldsNames.set('orderDocumentNumber', 'Nr zamówienia Subiekt');
csvExportFieldsNames.set('paymentDocumentNumber', 'Nr dokumentu sprzedaży');
csvExportFieldsNames.set('returnDocumentNumber', 'Nr zwrotu/korekty');
csvExportFieldsNames.set('stockIssueConfirmationDocumentNumber', 'Nr WZ');
csvExportFieldsNames.set('firstName', 'Imię');
csvExportFieldsNames.set('lastName', 'Nazwisko');
csvExportFieldsNames.set('companyName', 'Nazwa firmy');
csvExportFieldsNames.set('companyTaxNumber', 'NIP');
csvExportFieldsNames.set('address_1', 'Ulica');
csvExportFieldsNames.set('address_2', 'Nr budynku');
csvExportFieldsNames.set('address_3', 'Nr lokalu');
csvExportFieldsNames.set('postalCode', 'Kod pocztowy');
csvExportFieldsNames.set('city', 'Miasto');
csvExportFieldsNames.set('shipToDifferentAddress', 'Dostawa na inny adres');
csvExportFieldsNames.set('shippingAddress_1', 'Dostawa - ulica');
csvExportFieldsNames.set('shippingAddress_2', 'Dostawa - nr budynku');
csvExportFieldsNames.set('shippingAddress_3', 'Dostawa - nr lokalu');
csvExportFieldsNames.set('shippingPostalCode', 'Dostawa - kod pocztowy');
csvExportFieldsNames.set('shippingCity', 'Dostawa - miasto');
csvExportFieldsNames.set('shippingType', 'Forma dostawy');
csvExportFieldsNames.set('email', 'Email');
csvExportFieldsNames.set('phone', 'Telefon');
csvExportFieldsNames.set('clientNotes', 'Uwagi klienta');
csvExportFieldsNames.set('deliveryNotes', 'Uwagi dla kuriera');
csvExportFieldsNames.set('adminNotes', 'Uwagi administratora');
csvExportFieldsNames.set('chosenParcelLocker', 'Paczkomat');
csvExportFieldsNames.set('pesel', 'PESEL');
csvExportFieldsNames.set('role', 'Rola użytkownika');
csvExportFieldsNames.set('isActivated', 'Użytkownik aktywny');
csvExportFieldsNames.set('hasStructure', 'Posiada strukturę');
csvExportFieldsNames.set('isBlocked', 'Użytkownik zablokowany');
csvExportFieldsNames.set('externalId', 'ID użytkownika');
csvExportFieldsNames.set('taxNumber', 'NIP w zamówieniu');
csvExportFieldsNames.set('quantity', 'Ilość');
csvExportFieldsNames.set('subtotal', 'Wartość brutto pozycji');
csvExportFieldsNames.set('productName', 'Nazwa produktu');
csvExportFieldsNames.set('productId', 'ID produktu');
csvExportFieldsNames.set('parent_externalId', 'ID opiekuna');
csvExportFieldsNames.set('parent_firstName', 'Imię opiekuna');
csvExportFieldsNames.set('parent_lastName', 'Nazwisko opiekuna');
csvExportFieldsNames.set('parent_companyName', 'Nazwa firmy opiekuna');
csvExportFieldsNames.set('parent_address_1', 'Ulica opiekuna');
csvExportFieldsNames.set('parent_address_2', 'Nr budynku opiekuna');
csvExportFieldsNames.set('parent_address_3', 'Nr lokalu opiekuna');
csvExportFieldsNames.set('parent_postalCode', 'Kod pocztowy opiekuna');
csvExportFieldsNames.set('parent_city', 'Miasto opiekuna');
csvExportFieldsNames.set('parent_email', 'Email');
csvExportFieldsNames.set('parent_phone', 'Telefon');
csvExportFieldsNames.set('parent_taxNumber', 'Telefon');

const csvProductExportFieldsNames = new Map();
csvProductExportFieldsNames.set('grossPrice', 'Cena brutto');
csvProductExportFieldsNames.set('name', 'Nazwa');
csvProductExportFieldsNames.set('description', 'Opis');
csvProductExportFieldsNames.set('features', 'Właściwości');
csvProductExportFieldsNames.set('ingredients', 'Skład');
csvProductExportFieldsNames.set('application', 'Sposób użycia');
csvProductExportFieldsNames.set('untilEndOfStock', 'Do wyczerpania zapasów');
csvProductExportFieldsNames.set('new', 'Nowość');
csvProductExportFieldsNames.set('type', 'Rodzaj produktu');
csvProductExportFieldsNames.set('createdAt', 'Utworzono');
csvProductExportFieldsNames.set('updatedAt', 'Zmodyfikowano');
csvProductExportFieldsNames.set('activeFrom', 'Aktywny od');
csvProductExportFieldsNames.set('activeTo', 'Aktywny do');
csvProductExportFieldsNames.set('active', 'Aktywny');
csvProductExportFieldsNames.set('categories', 'Kategorie');

const csvSpecialOfferExportFieldsNames = new Map();
csvSpecialOfferExportFieldsNames.set('name', 'Nazwa');
csvSpecialOfferExportFieldsNames.set('rewardChooseType', 'Sposób wyboru nagród');
csvSpecialOfferExportFieldsNames.set('active', 'Aktywna');
csvSpecialOfferExportFieldsNames.set('description', 'Opis');
csvSpecialOfferExportFieldsNames.set('position', 'Pozycja');
csvSpecialOfferExportFieldsNames.set('start', 'Początek');
csvSpecialOfferExportFieldsNames.set('end', 'Koniec');
csvSpecialOfferExportFieldsNames.set('combineWithOthers', 'Łączy się z innymi');

const csvCategoryExportFieldsNames = new Map();
csvCategoryExportFieldsNames.set('name', 'Nazwa');
csvCategoryExportFieldsNames.set('active', 'Aktywna');
csvCategoryExportFieldsNames.set('position', 'Pozycja');
csvCategoryExportFieldsNames.set(
  'notAvailableInMainOrder',
  'Niedostępna w głównym zamówieniu'
);
csvCategoryExportFieldsNames.set('samplesLimit', 'Limit próbek w zamówieniu');
csvCategoryExportFieldsNames.set('productsAmount', 'Liczba produktów');

const csvUserExportFieldsNames = new Map();
csvUserExportFieldsNames.set('email', 'Email');
csvUserExportFieldsNames.set('phone', 'Telefon');
csvUserExportFieldsNames.set('pesel', 'PESEL');
csvUserExportFieldsNames.set('role', 'Rola');
csvUserExportFieldsNames.set('mlmRoleCurrent', 'Obecny status');
csvUserExportFieldsNames.set('mlmRoleStable', 'Poprzedni status');
csvUserExportFieldsNames.set('firstName', 'Imię');
csvUserExportFieldsNames.set('lastName', 'Nazwisko');
csvUserExportFieldsNames.set('isActivated', 'Aktywowany');
csvUserExportFieldsNames.set('hasStructure', 'Ma strukturę');
csvUserExportFieldsNames.set('isBlocked', 'Zablokowany');
csvUserExportFieldsNames.set('externalId', 'Publiczne ID');
csvUserExportFieldsNames.set('externalIdOld', 'ID archiwalne');
csvUserExportFieldsNames.set('taxStatus', 'Os. fiz/firma');
csvUserExportFieldsNames.set('userAcceptedMarketing1', 'Zgoda marketinogwa nr 1');
csvUserExportFieldsNames.set('userAcceptedMarketing2', 'Zgoda marketinogwa nr 2');
csvUserExportFieldsNames.set('userAcceptedPolicy', 'Akceptacja regulaminu');
csvUserExportFieldsNames.set('companyName', 'Nazwa firmy');
csvUserExportFieldsNames.set('parent', 'Opiekun');
csvUserExportFieldsNames.set('address_1', 'Ulica');
csvUserExportFieldsNames.set('address_2', 'Nr budynku');
csvUserExportFieldsNames.set('address_3', 'Nr lokalu');
csvUserExportFieldsNames.set('postalCode', 'Kod pocztowy');
csvUserExportFieldsNames.set('city', 'Miasto');
csvUserExportFieldsNames.set('shippingAddress_1', 'Korespondencyjny - ulica');
csvUserExportFieldsNames.set('shippingAddress_2', 'Korespondencyjny - nr budynku');
csvUserExportFieldsNames.set('shippingAddress_3', 'Korespondencyjny - nr lokalu');
csvUserExportFieldsNames.set('shippingPostalCode', 'Korespondencyjny - kod pocztowy');
csvUserExportFieldsNames.set('shippingCity', 'Korespondencyjny - miasto');
csvUserExportFieldsNames.set('createdAt', 'Utworzono');
csvUserExportFieldsNames.set('updatedAt', 'Zmodyfikowano');

const csvTransactionExportFieldsNames = new Map();
csvTransactionExportFieldsNames.set('user', 'Użytkownik');
csvTransactionExportFieldsNames.set('externalId', 'ID użytkownika');
csvTransactionExportFieldsNames.set('amount', 'Kwota');
csvTransactionExportFieldsNames.set('balance', 'Saldo');
csvTransactionExportFieldsNames.set('status', 'Status');
csvTransactionExportFieldsNames.set('title', 'Opis');
csvTransactionExportFieldsNames.set('type', 'Typ');
csvTransactionExportFieldsNames.set('executor', 'Wykonał');
csvTransactionExportFieldsNames.set('createdAt', 'Utworzono');
csvTransactionExportFieldsNames.set('updatedAt', 'Zmodyfikowano');

const productTypeName = new Map();
productTypeName.set(ProductType.STANDARD, 'Standardowy');
productTypeName.set(ProductType.MARKETING, 'Marketingowy');

const transactionTypeName = new Map();
transactionTypeName.set(VirtualWalletTransactionType.ORDER, 'Zamówienie');
transactionTypeName.set(VirtualWalletTransactionType.ADD, 'Uznanie');
transactionTypeName.set(VirtualWalletTransactionType.SUB, 'Obciążenie');
transactionTypeName.set(VirtualWalletTransactionType.PAYOUT, 'Wypłata');
transactionTypeName.set(VirtualWalletTransactionType.PERIOD, 'Prowizja');

const transactionStatusName = new Map();
transactionStatusName.set(VirtualWalletTransactionStatus.WAITING, 'Oczekuje');
transactionStatusName.set(VirtualWalletTransactionStatus.FINISHED, 'Zatwierdzona');
transactionStatusName.set(VirtualWalletTransactionStatus.CANCELED, 'Anulowana');

const billingStatuses = new Map();
billingStatuses.set(BillingStatus.NEW, 'Nowy');
billingStatuses.set(BillingStatus.PAID, 'Wypłacony');

const translate = (
  phrase:
    | OrderStatus
    | PaymentDocument
    | DeliveryStatus
    | PaymentStatus
    | SubiektStatus
    | PaymentType
    | ShippingType
    | UserRole
    | ProductType
    | VirtualWalletTransactionType
    | VirtualWalletTransactionStatus,
  dictionary: Map<
    | OrderStatus
    | PaymentDocument
    | DeliveryStatus
    | PaymentStatus
    | SubiektStatus
    | PaymentType
    | ShippingType
    | UserRole
    | ProductType
    | VirtualWalletTransactionType
    | VirtualWalletTransactionStatus,
    string
  >,
  defaultPhrase?: string
) => {
  if (dictionary.has(phrase)) {
    return dictionary.get(phrase);
  }

  if (typeof defaultPhrase !== 'undefined') {
    return defaultPhrase;
  }
  return phrase;
};

export {
  transactionTypeName,
  transactionStatusName,
  orderStatusName,
  deliveryStatusName,
  paymentStatusName,
  subiektStatusName,
  paymentDocumentName,
  shippingTypeName,
  paymentTypeName,
  productTypeName,
  userRoleName,
  csvExportFieldsNames,
  csvProductExportFieldsNames,
  csvSpecialOfferExportFieldsNames,
  csvCategoryExportFieldsNames,
  csvUserExportFieldsNames,
  csvTransactionExportFieldsNames,
  billingStatuses,
  csvExportBillingsFieldsNames,
  csvExportVoucherFieldsNames,
  translate,
};
