import { Global } from '@emotion/react';
import { forwardRef } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ReactDatePickerStyles from 'styles/ReactDatePickerStyles';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, NoSsr, TextFieldProps } from '@mui/material';

// github.com/wojtekmaj/react-date-picker

type IReactDateTimePicker = {
  value: Date;
  format?: string;
} & Partial<TextFieldProps>;

export const ReactDateTimePicker = forwardRef<HTMLInputElement, IReactDateTimePicker>(
  function ReactDateTimePicker(props, ref) {
  const format = props.format ?? 'y-MM-dd H:mm:ss';

    return (
      <NoSsr>
        <Global styles={ReactDatePickerStyles} />
        <Box ref={ref} tabIndex={0} className="css-specificity-wrapper--date-picker">
          <DateTimePicker
            {...props}
            disableClock
            format={format}
            calendarIcon={<PendingActionsIcon color={'disabled'} />}
          />
        </Box>
      </NoSsr>
    );
  }
);
