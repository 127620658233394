import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilter } from 'features/types/filters';
import { baseBackendUrl } from 'utils/config';
import { IMlmRole } from '../Roles/rolesSlice';
import { Paginated } from '../types/paginatedQuery';
import { RootState } from 'app/redux/store';
import { IFile } from 'features/Files/filesSlice';
export interface ICategory {
  id: number;
  name: string;
  header: string;
  description: string;
  active: boolean;
  availableFor: Partial<IMlmRole>[];
  position: number;
  notAvailableInMainOrder: boolean;
  samplesLimit: number;
  productsAmount: number;
  file: IFile;
}

const categoriesSlice = createApi({
  reducerPath: 'categories',
  baseQuery: fetchBaseQuery({
    baseUrl: baseBackendUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Category', 'Categories'],
  endpoints: (builder) => ({
    getAllCategories: builder.query<Paginated<ICategory>, void>({
      query: () => 'categories',
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Category' as const, id })),
              { type: 'Category', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Category', id: 'PARTIAL-LIST' }],
    }),
    getFilteredCategories: builder.query<Paginated<ICategory>, IFilter>({
      query: (filters) =>
        `categories?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Category' as const, id })),
              { type: 'Category', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Category', id: 'PARTIAL-LIST' }],
    }),
    getCategory: builder.query<ICategory, number>({
      query: (id) => `categories/${id}`,
      providesTags: (result) => (result ? [{ type: 'Category', id: result.id }] : []),
    }),
    addCategory: builder.mutation<any, any>({
      query(body) {
        return {
          url: `categories`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'Category', body },
          { type: 'Categories', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    editCategory: builder.mutation<any, any>({
      query({ id, formData }) {
        return {
          url: `categories/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'Category', body },
          { type: 'Categories', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteManyCategories: builder.mutation<ICategory[], number[]>({
      query(body) {
        return {
          url: `categories/`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'Category', body },
          { type: 'Categories', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteOneCategory: builder.mutation<ICategory, number>({
      query(id) {
        return {
          url: `categories/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'Category', id },
          { type: 'Categories', body: 'PARTIAL-LIST' },
        ];
      },
    }),
  }),
});

export default categoriesSlice;
