import {
  mdiAccountCircle,
  mdiAccountGroup, mdiBullet, mdiCardText,
  mdiCartVariant,
  mdiCash,
  mdiCogOutline, mdiDatabaseArrowDown, mdiFormatListBulleted, mdiFormatListNumbered, mdiListBox, mdiListStatus,
  mdiLogout, mdiMessage, mdiPercent, mdiSyncCircle,
  mdiTabletDashboard,
  mdiTrophy, mdiViewList,
} from '@mdi/js';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Icon from '@mdi/react';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ListIcon from '@mui/icons-material/List';
import PercentIcon from '@mui/icons-material/Percent';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled as styledMUI } from '@mui/material/styles';
import DiscountIcon from 'components/icons/DiscountIcon';
import CategoriesSlice from 'features/Categories/catogoriesSlice';
import {clearBuyingUser, logoutUser, UserRole} from 'features/User/UserSlice';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import MenuSingleItem from './MenuSingleItem';
import { SubmenuSingleItem as SubmenuItem } from './SubmenuSingleItem';
import { mdiPaperclipPlus } from '@mdi/js';
import { mdiBriefcasePlus } from '@mdi/js';
import { Box } from '@mui/material';
import DialogOrderAs from 'components/Orders/DialogOrderAs';
import { useState } from 'react';
import { AppDispatch } from 'app/redux/store';
import {clearLocalStorage} from "../../utils/localStorage";
import {orderStatusName} from "../../utils/dictionaries";


const { useGetAllCategoriesQuery } = CategoriesSlice;

interface StyledListItemIcon {
  primary?: string;
  secondary?: string;
}

export const StyledListItemIcon = styledMUI(ListItemIcon)<StyledListItemIcon>`
  color: currentColor;
`;

export const LoggedInMenuItems = ({ drawerState, viewportMobile, userSessionData }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { data: allCategories } = useGetAllCategoriesQuery();

  const router = useRouter();
  const subpageURI = router.query.subpage || router.asPath;

  const dispatch = useDispatch<AppDispatch>();

  const updateProductsStock = () => {

  }

  const onSignOut = () => {
    clearLocalStorage();
    dispatch(clearBuyingUser());
    dispatch(logoutUser());
  };

  return (
    <>
      <div className="flex flex-col dashboard__page-controls">
        <List>
          {/*
            PULPIT
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Aktualności'}
            drawerState={drawerState}
            href={`../../pulpit`}
            selected={router.route === '/pulpit'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiTabletDashboard} size={1.25} />
              </StyledListItemIcon>
            }
          />


          {userSessionData.role === UserRole.USER && (
            <MenuSingleItem
              userSessionData={userSessionData}
              //onlyForRoles={[UserRole.ADMIN]}
              viewportMobile={viewportMobile}
              label={'Dodaj zamówienie'}
              drawerState={drawerState}
              href={`/sklep/koszyk`}
              selected={false}
              passHref
              icon={
                <StyledListItemIcon primary={'true'}>
                  <Icon path={mdiBriefcasePlus} size={1.25} />
                </StyledListItemIcon>
              }
            />
          )}


          {/* DODAJ ZAMÓWIENIE */}
          <span onClick={() => setIsDialogOpen(true)}>
            <MenuSingleItem
              userSessionData={userSessionData}
              onlyForRoles={[UserRole.ADMIN]}
              viewportMobile={viewportMobile}
              label={'Dodaj zamówienie'}
              drawerState={drawerState}
              href={''}
              selected={false}
              passHref={false}
              icon={
                <StyledListItemIcon primary={'true'}>
                  <Icon path={mdiBriefcasePlus} size={1.25} />
                </StyledListItemIcon>
              }
              onClick={(e) => e.preventDefault()}
            />
          </span>


          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Sklep'}
            drawerState={drawerState}
            href={`../../sklep`}
            selected={router.pathname.startsWith('/sklep')}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiCartVariant} size={1.25} />
              </StyledListItemIcon>
            }
          >
            <>
              {allCategories?.data
                ?.slice()
                .sort((a, b) => a.position - b.position)
                .filter((category) => category.notAvailableInMainOrder !== true)
                .map((category, i) =>
                  category.active ? (
                    <SubmenuItem
                      userSessionData={userSessionData}
                      key={i}
                      viewportMobile={viewportMobile}
                      label={`${category?.name}`}
                      drawerState={drawerState}
                      href={`../../sklep/kategoria/${category?.name.toLowerCase()}`}
                      selected={
                        router.asPath ===
                        `/sklep/kategorie/${category?.name.toLowerCase()}`
                      }
                      disableGutters
                      icon={<FormatListNumberedOutlinedIcon />}
                    />
                  ) : (
                    ''
                  )
                )}
            </>
          </MenuSingleItem>

          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            onlyForRoles={[UserRole.USER]}
            label={'Zamówienia'}
            drawerState={drawerState}
            href={`/zamowienia`}
            selected={router.pathname.startsWith('/zamowienia')}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiCash} size={1.25} />
              </StyledListItemIcon>
            }
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            onlyForRoles={[UserRole.ADMIN]}
            label={'Zamówienia'}
            drawerState={drawerState}
            href={`/zamowienia`}
            selected={router.pathname.startsWith('/zamowienia')}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiCash} size={1.25} />
              </StyledListItemIcon>
            }
          >

            {Array.from(orderStatusName).map((elem, i) =>
              <SubmenuItem
                userSessionData={userSessionData}
                viewportMobile={viewportMobile}
                onlyForRoles={[UserRole.ADMIN]}
                key={i}
                label={`${elem[1]}`}
                drawerState={drawerState}
                href={`/zamowienia/${elem[0].toLowerCase()}`}
                selected={
                  router.asPath === `/zamowienia/${elem[0].toLowerCase()}`
                }
                disableGutters
                icon={<FormatListNumberedOutlinedIcon />}
              />
            )}

          </MenuSingleItem>

          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Kategorie'}
            drawerState={drawerState}
            href={`/sklep/kategorie`}
            selected={router.route === '/sklep/kategorie'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiFormatListNumbered} size={1.25} />
              </StyledListItemIcon>
            }
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Produkty'}
            drawerState={drawerState}
            href={`/sklep/produkty`}
            selected={router.route === '/sklep/produkty'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiFormatListBulleted} size={1.25} />
              </StyledListItemIcon>
            }
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Promocje'}
            drawerState={drawerState}
            href={`/sklep/promocje`}
            selected={router.route === '/sklep/promocje'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiPercent} size={1.25} />
              </StyledListItemIcon>
            }
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Struktura'}
            drawerState={drawerState}
            href={`../../struktura`}
            selected={router.pathname.startsWith('/struktura')}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiAccountGroup} size={1.25} />
              </StyledListItemIcon>
            }
          >
            <SubmenuItem
              userSessionData={userSessionData}
              viewportMobile={viewportMobile}
              label={`Użytkownicy`}
              drawerState={drawerState}
              href={`../../struktura/users-regular`}
              selected={subpageURI === 'users-regular'}
              disableGutters
              icon={<ListIcon />}
            />
            <SubmenuItem
              userSessionData={userSessionData}
              viewportMobile={viewportMobile}
              onlyForRoles={[UserRole.ADMIN]}
              label={`Administratorzy`}
              drawerState={drawerState}
              href={`../../struktura/users-admin`}
              selected={subpageURI === 'users-admin'}
              disableGutters
              icon={<ListIcon />}
            />
            <SubmenuItem
              userSessionData={userSessionData}
              viewportMobile={viewportMobile}
              onlyForRoles={[UserRole.ADMIN]}
              label={`Wirtualne portfele`}
              drawerState={drawerState}
              href={`../../struktura/virtual-wallet`}
              selected={subpageURI === 'virtual-wallet'}
              disableGutters
              icon={<ListIcon />}
            />
          </MenuSingleItem>

          {/*
            AWANSE
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Awanse'}
            drawerState={drawerState}
            href={`../../awanse`}
            selected={router.route === '/awanse'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiTrophy} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/*
            PROWIZJE
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Prowizje'}
            drawerState={drawerState}
            href={`../../prowizje`}
            selected={router.route === '/prowizje'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiCash} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/*
            Vouchery
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Vouchery'}
            onlyForRoles={[UserRole.ADMIN]}
            drawerState={drawerState}
            href={`../../vouchery`}
            selected={router.route === '/vouchery'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiCardText} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/*
            PLIKI DO POBRANIA
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            //onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Pliki do pobrania'}
            drawerState={drawerState}
            href={`../../pliki`}
            selected={router.route === '/pliki'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiPaperclipPlus} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/*
            NEWSY
          */}
          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Wiadomości'}
            drawerState={drawerState}
            href={`/admin/news`}
            selected={router.route === '/admin/news'}
            passHref
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiMessage} size={1.25} />
              </StyledListItemIcon>
            }
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Synchronizuj stany'}
            drawerState={drawerState}
            href={''}
            selected={false}
            passHref={false}
            icon={
                <StyledListItemIcon primary={'true'}>
                  <Icon path={mdiSyncCircle} size={1.25} />
                </StyledListItemIcon>
                }
            onClick={updateProductsStock}
          />

          <MenuSingleItem
            userSessionData={userSessionData}
            onlyForRoles={[UserRole.ADMIN]}
            viewportMobile={viewportMobile}
            label={'Eksport admina'}
            drawerState={drawerState}
            href={`/admin/eksport`}
            selected={false}
            passHref={false}
            icon={
                <StyledListItemIcon primary={'true'}>
                  <Icon path={mdiDatabaseArrowDown} size={1.25} />
                </StyledListItemIcon>
                }
          />

          <DialogOrderAs
            open={isDialogOpen}
            maxWidth={'md'}
            onClose={() => setIsDialogOpen(false)}
          />
        </List>
      </div>

      {/*
          BOTTOM MENU
      */}
      <Box
        sx={{ marginTop: 'auto', marginBottom: '2rem' }}
        className="flex flex-col dashboard__account-controls"
      >
        <List>
          {/* KONTO */}
          <MenuSingleItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={userSessionData?.name ? userSessionData.name : 'Imię usera'}
            drawerState={drawerState}
            href={''}
            selected={false}
            passHref={false}
            // href={`../../konto`}
            // selected={subpageURI === '/konto'}
            className={'pointer-events-none'}
            icon={
              <StyledListItemIcon primary={'true'}>
                <Icon path={mdiAccountCircle} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/* PROFIL */}
          <SubmenuItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Profil'}
            drawerState={drawerState}
            href={`../../konto/profil`}
            selected={router.route === '/profil'}
            icon={
              <StyledListItemIcon>
                <Icon path={mdiCogOutline} size={1.25} />
              </StyledListItemIcon>
            }
          />

          {/* PORTFEL */}
          <SubmenuItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Portfel'}
            drawerState={drawerState}
            href={`../../konto/portfel`}
            selected={router.route === '/profil'}
            icon={
              <StyledListItemIcon>
                <AccountBalanceWalletIcon sx={{ fontSize: 30 }} />
              </StyledListItemIcon>
            }
          />

          {/* KUPONY I VOUCHERY */}
          <SubmenuItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'MOJE KUPONY I VOUCHERY'}
            drawerState={drawerState}
            href={`../../konto/kupony-i-vouchery`}
            selected={router.route === '/konto/kupony-i-vouchery'}
            icon={<DiscountIcon />}
          />

          <SubmenuItem
            userSessionData={userSessionData}
            viewportMobile={viewportMobile}
            label={'Wyloguj'}
            drawerState={drawerState}
            href={``}
            selected={false}
            onClick={onSignOut}
            icon={
              <StyledListItemIcon>
                <Icon path={mdiLogout} size={1.25} />
              </StyledListItemIcon>
            }
          />
        </List>
      </Box>
    </>
  );
};

export default LoggedInMenuItems;
