import { forwardRef } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { useTheme } from '@mui/material';

export type LinkProps = Omit<MuiLinkProps, 'href'> &
  Omit<NextLinkProps, 'as' | 'passHref' | 'children'>;

const LinkStyled = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { href, replace, scroll, shallow, locale, ...muiProps },
  ref
) {
  const theme = useTheme();

  return (
    <NextLink
      href={href}
      target="_blank"
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      locale={locale}
      passHref>
      <MuiLink
        style={{
          fontWeight: theme.fontWeights.black,
        }}
        underline="hover"
        ref={ref}
        {...muiProps}
      />
    </NextLink>
  );
});

export default LinkStyled;
