import { Box, FormControl, FormHelperText } from '@mui/material';
import PriceField from 'components/PriceField';
import QtyField from 'components/QtyField';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function DaysNumber({ index }) {
  const { control, unregister, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.minValue`);
      unregister(`rewards.${index}.daysQty`);
      unregister(`rewards.${index}.shippingCostValue`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.daysQty`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box maxWidth={120}>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Liczba dni"
                  error={errors?.rewards?.[index]?.daysQty}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.daysQty?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name={`rewards.${index}.minValue`}
        control={control}
        defaultValue={400}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PriceField
                  field={field}
                  label={'Min. wartość zamówień katalogowo'}
                  error={errors?.rewards?.[index]?.minValue}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.minValue?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name={`rewards.${index}.shippingCostValue`}
        control={control}
        defaultValue={0}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PriceField
                  field={field}
                  label={'Koszt wysyłki'}
                  error={errors?.rewards?.[index]?.shippingCostValue}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.shippingCostValue?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
