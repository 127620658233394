import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@mui/material';
import DialogMUI from 'components/Dialog';
import { SingleSelectAutocomplete } from 'components/selectsAutocomplete/SingleSelectAutocomplete';
import UsersApi from 'features/Users/UsersApi';
import { setBuyerInOrderDetails } from 'features/Orders/orderDetailsSlice';
import router from 'next/router';
import { useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import theme from 'styles/theme';
import * as yup from 'yup';
import { setBuyingUser } from 'features/User/UserSlice';
import {clearCart, setDiscountForPreviousMonth} from "../../features/Cart/cartSlice";
import UserApi from "../../features/User/UserApi";

type Props = {
  open: boolean;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClose: () => void;
};

type DialogSpecialOfferProps = Props;

const schema = yup.object().shape({});

const DialogOrderAs = (props: DialogSpecialOfferProps) => {
  const { open, maxWidth, onClose } = props;
  const dispatch = useDispatch();
  const form = useRef(null);

  const allUsersQuery = UsersApi.endpoints.getAllUsers.useQuery();
  const { currentData: allUsersQueryData } = allUsersQuery;
  const allUsers = allUsersQueryData?.data;
  const [getDiscountForPreviousMonthQuery] = UserApi.endpoints.getDiscountForPreviousMonth.useLazyQuery();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, control } = methods;

  const getDiscountForPreviousMonth = async (id: number) => {
    const {data: discount} = await getDiscountForPreviousMonthQuery(id);
    dispatch(setDiscountForPreviousMonth(discount));
  }

  const onSubmit = async (data) => {
    onClose();
    const buyer = data.buyer;
    dispatch(setBuyerInOrderDetails({ ...buyer }));
    dispatch(setBuyingUser({ ...buyer }));
    getDiscountForPreviousMonth(buyer.id);
    dispatch(clearCart());
    router.push('/sklep/koszyk');
  };

  return (
    <DialogMUI open={open} maxWidth={maxWidth} onClose={onClose}>
      <Box mb={theme.spacings.m}>
        <Typography
          align="center"
          gutterBottom
          variant={'h4'}
          style={{ fontWeight: 'bold' }}>
          Zamów jako
        </Typography>
      </Box>

      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          ref={form}
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
            marginBottom: theme.spacings.xs2,
          }}>
          <Box mb={theme.spacings.xs2}>
            <Grid container direction="column">
              <Box mb={theme.spacings.xs2}>
                <Controller
                  name="buyer"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Box>
                        <SingleSelectAutocomplete
                          {...field}
                          label={'Wybierz zamawiającego'}
                          data={(allUsers && [...allUsers]) || []}
                          singleLabelParams={['firstName', 'lastName', 'externalId', 'person_information']}
                        />
                      </Box>
                    );
                  }}
                />
              </Box>
            </Grid>
          </Box>

          <Box gap={theme.spacings.xs4} className={'flex content-center items-center'}>
            <Button variant={'outlined'} onClick={onClose}>
              Anuluj
            </Button>
            <Button type="submit" variant={'contained'}>
              Zapisz
            </Button>
          </Box>
        </form>
      </FormProvider>
    </DialogMUI>
  );
};

export default DialogOrderAs;
