import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React, { Ref, useEffect, useState } from 'react';
import debounce from 'utils/debounce';
import Typography from '@mui/material/Typography';

interface INavbar {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any>;
  isUserLoggedIn?: boolean;
  navbarBgColor?: string;
  navbarWithBoxShadow?: boolean;
  navbarHeight?: number;
  viewportMobile?: boolean;
  menuStateMobile?: boolean;
  withPageTitleAtNavbar?: boolean;
  children: React.ReactElement;
}

export const Nav = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'navbarBgColor' && prop !== 'isUserLoggedIn',
})<INavbar>(({ navbarBgColor, theme, isUserLoggedIn }) => ({
  transitionDuration: `${theme.customTransitionsDurations.enteringScreen}`,
  transitionTimingFunction: `${theme.customTransitionsEasings.drawer}`,
  transitionDelay: '0',
  width: `100%`,
  marginLeft: `0`,
  backgroundColor: navbarBgColor ? navbarBgColor : theme.customColors.white,
  borderBottomRightRadius: theme.borderRadius.m,
  borderBottomLeftRadius: theme.borderRadius.m,
  paddingBottom: theme.spacings.xs4,
  paddingTop: theme.spacings.xs4,
  zIndex: theme.zIndexes.zIndexFront,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacings.xs4,
  },

  [theme.breakpoints.up('md')]: {
    paddingRight: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding900,
    paddingLeft: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding900,
  },

  [theme.breakpoints.up('lg')]: {
    paddingRight: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding1200,
    paddingLeft: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding1200,
  },

  [theme.breakpoints.up('xl')]: {
    paddingRight: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding1536,
    paddingLeft: isUserLoggedIn ? theme.spacings.m : theme.paddings.sidePadding1536,
  },

  img: {
    width: '40px',
    height: '40px',

    [theme.breakpoints.up('md')]: {
      width: '52px',
      height: '52px',
    },
  },
}));

const Navbar: React.FC<INavbar> = React.forwardRef(function NavbarComponent(
  {
    children,
    navbarBgColor,
    navbarHeight,
    navbarWithBoxShadow,
    menuStateMobile,
    viewportMobile,
    withPageTitleAtNavbar,
    isUserLoggedIn,
  },
  ref
) {
  const theme = useTheme();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(
    () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    },
    100,
    true
  );

  useEffect(() => {
    if (viewportMobile && menuStateMobile) {
      //return if menu is opened on mobile
      return;
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll, viewportMobile, menuStateMobile]);

  const router = useRouter();
  const parentPageTitle = router.asPath.split('/').filter((v) => v)[0];

  return (
    <div
      id="navbarContainer"
      style={{
        position: 'sticky',
        width: '100%',
        textAlign: 'center',
        transitionProperty: 'top',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'linear',
        top: visible ? '0' : `-${navbarHeight}px`,
        zIndex: theme.zIndexes.zIndexHeader,
      }}
      ref={ref}>
      <Nav
        id="navbar"
        isUserLoggedIn={isUserLoggedIn}
        navbarBgColor={navbarBgColor}
        className={`nav flex content-between items-center ${
          navbarWithBoxShadow ? 'box-shadow--standard' : ''
        }
            `}>
        <>
          {withPageTitleAtNavbar && isUserLoggedIn && !viewportMobile && (
            <Typography
              color={theme.palette.primary.dark}
              variant="h2"
              gutterBottom
              sx={{ textTransform: 'uppercase', minWidth: 'fit-content' }}>
              {parentPageTitle}
            </Typography>
          )}
          {children}
        </>
      </Nav>
    </div>
  );
});

export default Navbar;
