import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'app/redux/store';
import { PaymentDocument } from 'features/Orders/orderDetailsSlice';
import { IMlmRole } from 'features/Roles/rolesSlice';
import { IVirtualWallet } from 'features/VirtualWalletTransactions/virtualWalletTransactionsSlice';
import { IronSessionData } from 'iron-session';
import router from 'next/router';
import { clearLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { clearCart } from 'features/Cart/cartSlice';
import { clearOrderDetails } from 'features/Orders/orderDetailsSlice';

export type UserSessionData = IronSessionData['user'] | null;

export interface IUserState {
  userSessionData: UserSessionData;
  loggedUser?: IUser;
  buyingUser?: IUser;
  authUserLoadingState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  refreshUserLoadingState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  logoutUserLoadingState: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum TaxStatus {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export enum BillingType {
  FULL = 100,
  HALF = 50,
  NONE = 0,
}

export interface IUser {
  id: number;
  email: string;
  phone: string;
  pesel: string;
  password: string;
  role: UserRole;
  mlmRoleCurrent?: IMlmRole | null;
  mlmRoleStable?: IMlmRole | null;
  firstName: string;
  lastName: string;
  isActivated: boolean;
  isBlocked: boolean;
  hasStructure: boolean;
  activationLink: string;
  activationLinkExpirationDate: Date;
  resetLink: string;
  resetLinkExpirationDate: Date;
  profileImage?: string;
  currentHashedrefreshUser?: string;
  externalId: string;
  parent: {
    id: number;
    externalId: string;
    firstName: string;
    lastName: string;
  };
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  taxStatus: TaxStatus;
  companyTaxNumber: string;
  paymentDocument: PaymentDocument;
  defaultValue: string | boolean;
  userAcceptedMarketing1: boolean;
  userAcceptedMarketing2: boolean;
  userAcceptedPolicy: boolean;
  active: boolean;
  checked: boolean;
  companyName: string;
  taxNumber: string;
  address_1: string;
  address_2: string;
  address_3: string;
  postalCode: string;
  city: string;
  shipToDifferentAddress: boolean;
  shippingAddress_1: string;
  shippingAddress_2: string;
  shippingAddress_3: string;
  shippingPostalCode: string;
  shippingCity: string;
  subiektSymbol: string;
  note?: string;
  notesHistory?: any[];
  virtualWallet: IVirtualWallet;
  oldId?: string;
  billingType?: number;
}

export type IRole = {
  name: string;
  discount: number;
  additionalDiscount: number;
}

export type IProgressDataRow = {
  title: string;
  current: any;
  required: any;
  details: string;
  money: boolean;
}

export type IProgressData = {
  user: string,
  currentRole: IRole;
  nextRole: IRole;
  current: IProgressDataRow[];
  next: IProgressDataRow[];
}

export type IDashboardData = {
  role: IRole;
  groupTurnover: number;
  selfTurnover: number;
  usersInFirstLine: number;
  allUsersCurrentMonth: number;
  usersInFirstLineCurrentMonth: number;
  allUsers: number;
}

export type IProgressDataDetails = {
  type: string,
  data: IProgressDataDetailsRow[];
}

export type IProgressDataDetailsRow = {
  id: string,
  value: string;
}

export type IStructure<T> = {
  structure: IStructure<T>[];
  descendantsCount: number;
  totalCount: number;
} & T;

const initialState: IUserState = {
  userSessionData: null,
  loggedUser: null,
  buyingUser: null,
  authUserLoadingState: 'idle',
  refreshUserLoadingState: 'idle',
  logoutUserLoadingState: 'idle',
};

export const authUser = createAsyncThunk('auth/me', async () => {
  try {
    const response = await fetch('/api/auth/me').then((response) => {
      if (response.status == 200) {
        return response.json();
      } else {
        throw 'ERROR';
      }
    });
    return response;
  } catch (e) {}
});

export const refreshUser = createAsyncThunk(
  'auth/refresh',
  async (params, { dispatch }) => {
    const response = await fetch('/api/auth/refresh').then((response) => {
      if (response.status == 200) {
        return response.json();
      } else {
        dispatch(clearCart());
        dispatch(clearOrderDetails());
        clearLocalStorage();
        throw 'ERROR';
      }
    });

    return response;
  }
);

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (params, { dispatch }) => {
    const response = await fetch('/api/auth/logout').then((response) => response);
    dispatch(clearCart());
    dispatch(clearOrderDetails());
    clearLocalStorage();
    return response.status;
  }
);

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserSessionData: (state, action: PayloadAction<UserSessionData>) => {
      state.userSessionData = action.payload;
    },

    setLoggedUser: (state, action: PayloadAction<IUser>) => {
      state.loggedUser = action.payload;
    },

    setBuyingUser: (state, action: PayloadAction<IUser | null>) => {
      state.buyingUser = action.payload;
    },

    clearBuyingUser: (state) => {
      state.buyingUser = null;
      saveStateToLocalStorage('user', state);
    },
  },
  extraReducers: (builder) => {
    /* Auth */
    builder.addCase(authUser.pending, (state, action) => {
      state.authUserLoadingState = 'pending';
    });
    builder.addCase(authUser.rejected, (state, action) => {
      state.userSessionData ? (state.userSessionData = null) : null;
      state.authUserLoadingState = 'rejected';
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.userSessionData = action.payload;
      state.authUserLoadingState = 'fulfilled';
    });

    /* Refresh */
    builder.addCase(refreshUser.pending, (state, action) => {
      state.refreshUserLoadingState = 'pending';
    });
    builder.addCase(refreshUser.rejected, (state, action) => {
      // TODO - TEST IT!!!

      //state.userSessionData = null;
      //state.refreshUserLoadingState = 'rejected';
      router.push('/login');
      setTimeout(() => {
        router.reload();
      }, 100);
      return {
        ...initialState,
        refreshUserLoadingState: 'rejected',
        userSessionData: null,
      };
      //state.userSessionData ? (state.userSessionData = null) : null;
    });
    builder.addCase(refreshUser.fulfilled, (state, action) => {
      state.userSessionData = action.payload;
      state.refreshUserLoadingState = 'fulfilled';
    });

    /* Logout */
    builder.addCase(logoutUser.pending, (state) => {
      state.logoutUserLoadingState = 'pending';
    });
    builder.addCase(logoutUser.rejected, (state) => {
      state.logoutUserLoadingState = 'rejected';
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      //state.logoutUserLoadingState = 'fulfilled';
      //state = initialState;
      //state.buyingUser = null;
      router.push('/login');
      return { ...initialState, logoutUserLoadingState: 'fulfilled' };
    });
  },
});

export const { setUserSessionData, setLoggedUser, setBuyingUser, clearBuyingUser } =
  userSlice.actions;

export const userSelector = (state: AppState) => state.userSlice;

export default userSlice;
