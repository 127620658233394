import Typography from '@mui/material/Typography';
import { FC } from 'react';
import styled from '@emotion/styled';
import LinkStyled from 'components/LinkStyled/LinkStyled';
import theme from 'styles/theme';

interface IFooter {
  footerBgColor?: string;
  footerWithBoxShadow?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentFooterStyle?: any;
}

const StyledFooter = styled.footer`
  margin-left: auto;
  transition-duration: ${theme.customTransitionsDurations.enteringScreen};
`;

const StyledVersionInfo = styled.span`
  position: absolute;
  right: 1rem;
  color: ${theme.customColors.shadesSecondaryBrand70};
`;

export const Footer: FC<IFooter> = ({ footerBgColor, footerWithBoxShadow }) => {
  return (
    <StyledFooter
      id="colophon"
      className={`flex flex-col ${footerWithBoxShadow ? `box-shadow--standard` : ''}`}
      style={{
        backgroundColor: footerBgColor ? footerBgColor : '',
      }}>
      <div className="footer__top">
        <Typography variant="h6" align="center" gutterBottom={true}>
          Copyright ©️ 2022{' '}
          <LinkStyled href="https://justpolska.pl/">JUST Polska</LinkStyled>. Projekt i
          realizacja:{' '}
          <LinkStyled href="https://perfectone.pl/">
            Perfect One - Oprogramowanie dedykowane
          </LinkStyled>
          .
        </Typography>
      </div>
      <div className="footer__bottom">
        <div className="flex items-center content-center">
          <Typography variant="h6" align="center" gutterBottom={true}>
            <LinkStyled href="/regulamin_sklepu_internetowego.pdf">Regulamin serwisu</LinkStyled>
            <span className="ml--75 mr--75">|</span>
            <LinkStyled href="/polityka-prywatnosci">Polityka prywatności</LinkStyled>
          </Typography>
        </div>
      </div>
      <StyledVersionInfo>version: 1.2.1</StyledVersionInfo>
    </StyledFooter>
  );
};

export default Footer;
