import List from '@mui/material/List';
import CartMenuItem from 'components/Cart/CartMenuItem/CartMenuItem';

export const LoggedInSecondaryMenuItems = () => {
  const NavControls = () => {
    return (
      <>
        <CartMenuItem />
      </>
    );
  };

  return (
    <List
      className="full-w content-end items-center"
      sx={{
        textAlign: 'right',
      }}>
      <NavControls />
    </List>
  );
};
