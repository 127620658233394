import { Global as GlobalStyles } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AuthWrapper from 'components/AuthWrapper';
import {
  setUserSessionData,
  setLoggedUser,
} from 'features/User/UserSlice';
import { getIronSession } from 'iron-session';
import Layout from 'layout/Layout';
import { ironOptions } from 'lib/session/config';
import { DefaultSeo } from 'next-seo';
import { AppContext } from 'next/app';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';
import {Provider, useSelector} from 'react-redux';
import theme from 'styles/theme';
import ErrorBoundary from 'utils/errors/ErrorBoundary';
import { store } from '../app/redux/store';
import defaultSeoConfig from '../seo/default-seo.config';
import globalStyles from '../styles/globalStyles';
import { baseBackendUrl } from 'utils/config';
import { useEffect } from 'react';
import Shop from 'components/Shop';

export default function MyApp({ Component, pageProps }) {
  useEffect(() => {
    store.dispatch(setUserSessionData(pageProps.userSessionData));
  }, [pageProps.userSessionData]);

  return (
    <>
      <CookiesProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyles styles={globalStyles} />
            <StyledEngineProvider injectFirst>
              <ErrorBoundary>
                <AuthWrapper userSessionData={pageProps.userSessionData}>
                  <DefaultSeo {...defaultSeoConfig} />
                  <Toaster position="top-right" />
                  <Layout userSessionData={pageProps.userSessionData}>
                    <Shop
                      userSessionData={pageProps.userSessionData}>
                      <Component {...pageProps} />
                    </Shop>
                  </Layout>
                </AuthWrapper>
              </ErrorBoundary>
            </StyledEngineProvider>
          </ThemeProvider>
        </Provider>
      </CookiesProvider>
    </>
  );
}

// Better solution to consider in future:
// https://betterprogramming.pub/why-i-got-rid-of-getinitialprops-in-my-next-js-project-fc926e98ed61

MyApp.getInitialProps = async (appContext: AppContext) => {
  const req = appContext.ctx.req;
  const res = appContext.ctx.res;

  const session = await getIronSession(req, res, ironOptions);

  const DEFAULT_PROPS = {
    pageProps: {},
  };

  if (session?.user) {
    return {
      ...DEFAULT_PROPS,
      pageProps: {
        userSessionData: session.user,
      },
    };
  }

  return DEFAULT_PROPS;
};
