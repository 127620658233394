import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CartMenuItem from 'components/Cart/CartMenuItem/CartMenuItem';
import Drawer, {
  drawerWidthOpenDesktop,
  drawerWidthOpenMobile,
} from 'components/Drawer/Drawer';
import LogoLink from 'components/LogoLink/LogoLink';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import theme from 'styles/theme';
import { menuToggleDesktop, menuToggleMobile } from './menuSlice';
import Hamburger from 'components/Hamburger/Hamburger';

export default function Menu({
  isUserLoggedIn,
  navbarHeight,
  children,
  menuStateDesktop,
  menuStateMobile,
  viewportMobile,
}) {
  const dispatch = useDispatch();

  const toggleDrawerDesktop = () => {
    dispatch(menuToggleDesktop());
  };

  const toggleDrawerMobile = () => {
    dispatch(menuToggleMobile());
  };

  return (
    <>
      {viewportMobile ? (
        /* MOBILE */
        <div id="menuDashboard" className="flex content-between full-w relative">
          <LogoLink />
          <div className="flex navbar__menu relative full-w content-end items-center">
            <div className="mr--1">
              <CartMenuItem />
            </div>
            <IconButton
              disableRipple
              className={`${theme.zIndexes.zIndexFront}`}
              onClick={toggleDrawerMobile}>
              <Hamburger open={menuStateMobile} />
            </IconButton>
          </div>
          <Drawer
            anchor="left"
            variant="permanent"
            open={menuStateMobile}
            drawerWidthOpen={drawerWidthOpenMobile}
            classes={{
              paper: 'no-scrollbar',
              root: 'absolute',
            }}
            PaperProps={{
              sx: {
                top: `calc(${navbarHeight}px - ${theme.spacings.xs4})`, //to hide border-radius
                paddingTop: theme.spacings.xs4,
                visibility: 'visible!important' as 'visible',
                transform: 'translateX(0)!important' as 'translateX(0)',
              },
            }}>
            <Box
              sx={{
                width: 'auto',
                height: '100%',
              }}
              role="presentation">
              {children}
            </Box>
          </Drawer>
        </div>
      ) : (
        /* DESKTOP */
        <>
          {isUserLoggedIn && (
            <div
              id="menuDashboard"
              className="flex content-between relative box-shadow--standard">
              <Drawer
                variant="permanent"
                open={menuStateDesktop}
                drawerWidthOpen={drawerWidthOpenDesktop}
                classes={{
                  paper: 'no-scrollbar',
                }}>
                <LogoLink />
                <div className="absolute">
                  <IconButton
                    className="drawer-toggle-button"
                    style={{
                      position: 'fixed',
                      padding: '0',
                      borderRadius: '100%',
                      overflow: 'hidden',
                    }}
                    onClick={toggleDrawerDesktop}>
                    {menuStateDesktop ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
                {children}
              </Drawer>
            </div>
          )}
          {!isUserLoggedIn && (
            <>
              <LogoLink />
              {children}
              <div className="ml--2">
                <CartMenuItem />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
