import { Box, FormControl, FormHelperText } from '@mui/material';
import QtyField from 'components/QtyField';
import { SingleSelectAutocomplete } from 'components/selectsAutocomplete/SingleSelectAutocomplete';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function FreeProduct({ index, productsData }) {
  const { control, unregister, watch, formState, clearErrors } = useFormContext();

  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.product`);
      unregister(`rewards.${index}.qty`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.product`}
        control={control}
        render={({ field }) => {
          return (
            <FormControl error={!!errors?.rewards?.[index]?.product}>
              <SingleSelectAutocomplete
                {...field}
                uniqueKey={'sku'}
                label={'Produkt'}
                data={(productsData && [...productsData.data]) || []}
                singleLabelParams={['name', 'sku']}
                error={!!errors?.rewards?.[index]?.product}
              />
              <FormHelperText>
                <>{errors?.rewards?.[index]?.product?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.qty`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box maxWidth={120}>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Liczba"
                  error={errors?.rewards?.[index]?.qty}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.qty?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
