import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppDispatch } from 'app/redux/store';
import { UserSessionData } from 'features/User/UserSlice';
import DashboardPageContent from 'layout/Dashboard/DashboardPageContent';
import Footer from 'layout/Footer/Footer';
import Navbar from 'layout/Navbar/Navbar';
import Image from 'next/image';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { useEffect } from 'react';
import useDimensions from 'react-cool-dimensions';
import { useDispatch, useSelector } from 'react-redux';
import LoggedInMenuItems from './Menu/LoggedInMenuItems';
import { LoggedInSecondaryMenuItems } from './Menu/LoggedInSecondaryMenuItems';
import Menu from './Menu/Menu';
import { menuSelector, saveNavbarHeight } from './Menu/menuSlice';
import { PublicMenuItems } from './Menu/PublicMenuItems';

export interface LayoutProps {
  userSessionData: UserSessionData;
  children: React.ReactNode;
}

const MainWrapperRegular = ({ className, children }) => {
  return (
    <main className={`${className ? className : ''} flex flex-col page-without-sidemenu`}>
      {children}
    </main>
  );
};

const MainWrapperWithDashboard = ({ className, children, dashboardContentBg }) => {
  return (
    <main className={className}>
      <DashboardPageContent dashboardContentBg={dashboardContentBg}>
        {children}
      </DashboardPageContent>
    </main>
  );
};

export const Layout: React.FC<LayoutProps> = (props) => {
  const { children, userSessionData } = props;

  const isLoggedIn = !!userSessionData;
  const theme = useTheme();
  const router = useRouter();
  const viewportMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const dispatch = useDispatch<AppDispatch>();

  const getLayoutSettings = () => {
    const layoutBgImageDefault = (active, fullSizeImageURL, blurDataImageURL) => {
      if (active) {
        return (
          <div id="layoutBgImage" className="bg-image__wrapper">
            <div className="overlay overlay--primary">
              <Image
                src={fullSizeImageURL}
                className="bg-image"
                alt="Just Polska Background Image"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                quality={100}
                placeholder="blur"
                blurDataURL={blurDataImageURL}
              />
            </div>
          </div>
        );
      }
    };

    const pathClass = router.asPath.split('/').pop() || 'home';

    const landingPagesDefaultSettings = {
      withNavbar: true,
      isUserLoggedIn: isLoggedIn,
      withPageTitleAtNavbar: false,
      navbarBgColor: theme.customColors.white,
      navbarWithBoxShadow: false,
      layoutBgImage: isLoggedIn
        ? layoutBgImageDefault(false, '', '')
        : layoutBgImageDefault(
            true,
            '/images/landing_bg_image.png',
            '/images/landing_bg_image_lq.png'
          ),
      layoutBgColor: '',
      footerBgColor: isLoggedIn
        ? theme.customColors.white
        : theme.customColors.semiTran70,
      footerWithBoxShadow: false,
      pageClass: pathClass,
    };

    const formOnlyPagesDefaultSettings = {
      withNavbar: false,
      isUserLoggedIn: false,
      navbarBgColor: theme.customColors.white,
      navbarWithBoxShadow: false,
      layoutBgImage: layoutBgImageDefault(
        true,
        '/images/landing_bg_image.png',
        '/images/landing_bg_image_lq.png'
      ),
      layoutBgColor: '',
      footerBgColor: theme.customColors.semiTran70,
      footerWithBoxShadow: false,
      pageClass: pathClass,
    };

    const dashboardPagesDefaultSettings = {
      withNavbar: true,
      isUserLoggedIn: isLoggedIn,
      withPageTitleAtNavbar: true,
      navbarWithBoxShadow: true,
      layoutBgImage: layoutBgImageDefault(false, '', ''),
      layoutBgColor: theme.customColors.white,
      dashboardContentBg: theme.customColors.white,
      footerBgColor: theme.customColors.white,
      footerWithBoxShadow: true,
      pageClass: pathClass,
    };

    const shopPagesDefaultSettings = {
      withNavbar: true,
      isUserLoggedIn: isLoggedIn,
      withPageTitleAtNavbar: true,
      navbarWithBoxShadow: true,
      layoutBgImage: layoutBgImageDefault(false, '', ''),
      layoutBgColor: theme.customColors.white,
      dashboardContentBg: theme.customColors.white,
      footerBgColor: theme.customColors.white,
      footerWithBoxShadow: true,
      pageClass: pathClass,
    };

    let settings;

    // Pages with Dashboard - default settings
    settings = dashboardPagesDefaultSettings;

    // Home Page & landing pages without dashboard
    if (router.pathname === '/') {
      settings = landingPagesDefaultSettings;
    }

    // Shop
    if (router.pathname.startsWith('/sklep')) {
      settings = shopPagesDefaultSettings;
    }

    // Login/Registration type pages
    if (
      router.pathname.startsWith('/login') ||
      router.pathname.startsWith('/registration') ||
      router.pathname.startsWith('/password-reset') ||
      router.pathname.startsWith('/reset-password-request') ||
      router.pathname.startsWith('/account-activation') ||
      router.pathname.startsWith('/set-password')
    ) {
      settings = formOnlyPagesDefaultSettings;
    }

    return settings;
  };

  const {
    withNavbar,
    withPageTitleAtNavbar,
    isUserLoggedIn,
    navbarBgColor,
    navbarWithBoxShadow,
    dashboardContentBg,
    layoutBgImage,
    layoutBgColor,
    footerBgColor,
    footerWithBoxShadow,
    pageClass,
  } = getLayoutSettings();

  const { observe, height } = useDimensions({
    onResize: ({ observe, unobserve }) => {
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    },
  });

  const navbarHeight = height;

  useEffect(() => {
    dispatch(saveNavbarHeight(navbarHeight));
  }, [navbarHeight, dispatch]);

  const menu = useSelector(menuSelector);
  const menuStateDesktop = menu.menuStateDesktop;
  const menuStateMobile = menu.menuStateMobile;

  return (
    <Box
      className={`${pageClass} page relative`}
      sx={{
        backgroundColor: `${layoutBgColor ? layoutBgColor : ''}`,
      }}>
      {layoutBgImage ? layoutBgImage : ''}
      <div className={`page__wrapper full-w`}>
        {isUserLoggedIn && (
          <>
            {withNavbar && viewportMobile && (
              <>
                <Navbar
                  ref={observe}
                  isUserLoggedIn={isUserLoggedIn}
                  withPageTitleAtNavbar={withPageTitleAtNavbar}
                  navbarBgColor={navbarBgColor}
                  navbarWithBoxShadow={navbarWithBoxShadow}
                  navbarHeight={navbarHeight}
                  viewportMobile={viewportMobile}
                  menuStateMobile={menuStateMobile}>
                  <Menu
                    isUserLoggedIn={isUserLoggedIn}
                    navbarHeight={navbarHeight}
                    menuStateDesktop={menuStateDesktop}
                    menuStateMobile={menuStateMobile}
                    viewportMobile={viewportMobile}>
                    <LoggedInMenuItems
                      drawerState={menuStateDesktop}
                      viewportMobile={viewportMobile}
                      userSessionData={userSessionData}
                    />
                  </Menu>
                </Navbar>
                <div
                  className="mobile-page__content flex flex-col full-w relative"
                  style={{ gap: theme.spacings.xs4 }}>
                  <MainWrapperRegular className={`${pageClass}__main`}>
                    {children}
                  </MainWrapperRegular>
                </div>
                <Footer
                  footerBgColor={footerBgColor}
                  footerWithBoxShadow={footerWithBoxShadow}
                />
              </>
            )}

            <NextNProgress
              color="#29D"
              nonce="my-NextNProgress-nonce"
              startPosition={0.1}
              stopDelayMs={200}
              height={3}
              showOnShallow={true}
              options={{ easing: 'ease', speed: 500, showSpinner: false }}
            />

            {withNavbar && !viewportMobile && (
              <div className="flex" style={{ gap: theme.spacings.xs4 }}>
                <Menu
                  isUserLoggedIn={isUserLoggedIn}
                  navbarHeight={navbarHeight}
                  menuStateDesktop={menuStateDesktop}
                  menuStateMobile={menuStateMobile}
                  viewportMobile={viewportMobile}>
                  <LoggedInMenuItems
                    drawerState={menuStateDesktop}
                    viewportMobile={viewportMobile}
                    userSessionData={userSessionData}
                  />
                </Menu>
                <div
                  className="page__content flex flex-col full-w relative"
                  style={{ gap: theme.spacings.xs4, paddingRight: theme.spacings.xs4 }}>
                  <Navbar
                    ref={observe}
                    isUserLoggedIn={isUserLoggedIn}
                    withPageTitleAtNavbar={withPageTitleAtNavbar}
                    navbarBgColor={navbarBgColor}
                    navbarWithBoxShadow={navbarWithBoxShadow}
                    navbarHeight={navbarHeight}
                    viewportMobile={viewportMobile}
                    menuStateMobile={menuStateMobile}>
                    <LoggedInSecondaryMenuItems />
                  </Navbar>

                  <MainWrapperWithDashboard
                    dashboardContentBg={dashboardContentBg}
                    className={`${pageClass}__main box-shadow--standard`}>
                    {children}
                  </MainWrapperWithDashboard>
                  <Footer
                    footerBgColor={footerBgColor}
                    footerWithBoxShadow={footerWithBoxShadow}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {!isUserLoggedIn && (
          <>
            {withNavbar && (
              <Navbar
                ref={observe}
                isUserLoggedIn={isUserLoggedIn}
                withPageTitleAtNavbar={withPageTitleAtNavbar}
                navbarBgColor={navbarBgColor}
                navbarWithBoxShadow={navbarWithBoxShadow}
                navbarHeight={navbarHeight}
                viewportMobile={viewportMobile}
                menuStateMobile={menuStateMobile}>
                <Menu
                  isUserLoggedIn={isUserLoggedIn}
                  navbarHeight={navbarHeight}
                  menuStateDesktop={menuStateDesktop}
                  menuStateMobile={menuStateMobile}
                  viewportMobile={viewportMobile}>
                  <PublicMenuItems viewportMobile={viewportMobile} />
                </Menu>
              </Navbar>
            )}
            <MainWrapperRegular className={`${pageClass}__main`}>
              {children}
            </MainWrapperRegular>
            <Footer
              footerBgColor={footerBgColor}
              footerWithBoxShadow={footerWithBoxShadow}
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default Layout;
