import { Box, FormControl, FormHelperText } from '@mui/material';
import PercentageField from 'components/PercentageField';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function ProductTurnover({ index }) {
  const { control, unregister, formState } = useFormContext();

  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.value`);
      unregister(`rewards.${index}.maxDiscountValueOfPriceValue`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={70}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PercentageField
                  field={field}
                  label={'Wysokość rabatu na produkt'}
                  error={errors?.rewards?.[index]?.value}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.value?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        name={`rewards.${index}.maxDiscountValueOfPriceValue`}
        control={control}
        defaultValue={10}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PercentageField
                  field={field}
                  label={'Max rabat jako procent wartosci zamówienia'}
                  error={errors?.rewards?.[index]?.maxDiscountValueOfPriceValue}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.maxDiscountValueOfPriceValue?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
