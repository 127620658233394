import styled from '@emotion/styled';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { List } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled as styledMUI } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { UserRole, UserSessionData } from 'features/User/UserSlice';
import Link from 'next/link';
import { FC, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'styles/theme';
import { menuToggleMobile } from './menuSlice';

export interface IMenuItem {
  label: string;
  href: string;
  passHref?: boolean;
  selected: boolean;
  className?: string;
  drawerState?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  children?: string | JSX.Element | JSX.Element[];
  onClick?: MouseEventHandler;
  disableGutters?: boolean;
  viewportMobile?: boolean;
  onlyForRoles?: UserRole[];
  userSessionData: UserSessionData;
}

export interface IStyledLink {
  primary?: string;
  secondary?: string;
  color?: string;
}

const StyledMenuLink = styled.a<IStyledLink>`
  display: flex;
  text-transform: uppercase;
  width: 100%;
  color: currentColor;
  ${(props) => (props.color ? `color: ${props.color}!important;` : ``)}
`;

interface StyledListItemIcon {
  primary?: string;
  secondary?: string;
}

export const StyledListItemButton = styledMUI(ListItemButton)`
  color: currentColor;
`;

export const StyledListItem = styledMUI(ListItem)`
  color: currentColor;
`;

export const StyledListItemIcon = styledMUI(ListItemIcon)<StyledListItemIcon>`
  color: currentColor;

  svg {
    color: currentColor;
  }
`;

export const StyledTooltip = styledMUI(
  ({ className, ...props }: TooltipProps): JSX.Element => (
    <Tooltip {...props} classes={{ popper: className }}>
      {props.children}
    </Tooltip>
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.customColors.colorsSecondaryBrand,
    color: theme.customColors.white,
    textTransform: 'uppercase',
    padding: '6px 10px',
    boxShadow: 'none',
    borderRadius: theme.borderRadius.m,
    fontSize: 16,
    lineHeight: '26px',
    fontFamily: theme.fonts.primary,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.customColors.colorsSecondaryBrand,
  },
}));

export const MenuSingleItem: FC<IMenuItem> = ({
  onlyForRoles,
  userSessionData,
  label,
  drawerState,
  href,
  passHref,
  children,
  selected,
  icon,
  viewportMobile,
  onClick,
  className,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [clickedOnce, setClickedOnce] = useState(false);

  const dispatch = useDispatch();

  const handleCollapseSubmenu = () => {
    if (!collapsed && !clickedOnce) {
      setCollapsed(true);
      setClickedOnce(true);
      return;
    }

    if (collapsed && clickedOnce) {
      setClickedOnce(false);
      setCollapsed(false);
      return;
    }

    if (collapsed && !clickedOnce) {
      setCollapsed(true);
      setClickedOnce(true);
      return;
    }

    if (!collapsed && clickedOnce) {
      setCollapsed(true);
      setClickedOnce(false);
      return;
    }
  };

  const handleClickOnItemIcon = () => {
    if (viewportMobile) {
      dispatch(menuToggleMobile());
    }
    if (children && !drawerState) {
      handleCollapseSubmenu();
    }
  };

  if (onlyForRoles && !onlyForRoles.includes(userSessionData?.role)) {
    return <></>;
  } else {
    return (
      <li
        className={`menu-item ${
          children ? 'menu-item--with-children' : ''
        } flex items-start relative ${className ? className : null}`}>
        <StyledListItemButton
          disableRipple
          selected={selected}
          className={`flex flex-col items-start`}>
          <div
            className="flex items-center full-w"
            onClick={onClick ? onClick : handleClickOnItemIcon}>
            {href.length > 0 ? (
              <Link href={href} passHref={passHref}>
                <StyledMenuLink primary={'true'}>
                  <StyledTooltip
                    title={label}
                    arrow
                    placement="right"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 300 }}
                    disableHoverListener={drawerState ? true : false}>
                    {icon}
                  </StyledTooltip>
                  <ListItemText
                    className="menu-item--text"
                    disableTypography={true}
                    primary={label}
                  />
                </StyledMenuLink>
              </Link>
            ) : (
              <StyledMenuLink primary={'true'}>
                <StyledTooltip
                  title={label}
                  arrow
                  placement="right"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
                  disableHoverListener={drawerState ? true : false}>
                  {icon}
                </StyledTooltip>
                <ListItemText disableTypography={true} primary={label} />
              </StyledMenuLink>
            )}
          </div>

          {children && (
            <Collapse
              in={collapsed}
              timeout="auto"
              unmountOnExit
              className={'full-w'}
              sx={{ paddingLeft: theme.spacings.xs8 }}>
              <List component="div" disablePadding>
                {children}
              </List>
            </Collapse>
          )}
        </StyledListItemButton>

        {children && (
          <IconButton
            onClick={handleCollapseSubmenu}
            className={`pointer expand-icon`}
            sx={{
              marginRight: `${viewportMobile ? '0.85rem' : ''} `,
            }}>
            {children && (collapsed ? <ExpandLess /> : <ExpandMore />)}
          </IconButton>
        )}
      </li>
    );
  }
};

export default MenuSingleItem;
