import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { SHORT_INPUT_LENGTH } from 'utils/constans';

type PriceField = {
  field: FieldValues;
  fullWidth?: boolean;
} & TextFieldProps;

export const PriceField = forwardRef<HTMLInputElement, PriceField>(function PriceValue(
  props,
  ref
) {
  const { field, label, fullWidth, ...rest } = props;

  return (
    <FormControl>
      <Box maxWidth={fullWidth ? undefined : SHORT_INPUT_LENGTH}>
        <TextField
          {...rest}
          {...field}
          ref={ref}
          type="number"
          label={label}
          onKeyDown={(event) => {
            if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
              event.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
          }}
          inputProps={{ min: 0, step: '0.01', lang: 'pl-PL' }}
        />
      </Box>
    </FormControl>
  );
});

export default PriceField;
