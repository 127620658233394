import { useTheme } from '@mui/material';
import List from '@mui/material/List';
import { Button } from '@mui/material';
import Router from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { menuToggleMobile } from './menuSlice';

export const PublicMenuItems = ({ viewportMobile }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const onSignIn = () => {
    Router.push('/login');
    dispatch(menuToggleMobile());
  };

  const onSignUp = () => {
    Router.push('/registration');
    dispatch(menuToggleMobile());
  };

  return (
    <List
      className={`full-w flex content-end ${
        viewportMobile && 'flex-col content-center items-center'
      }`}
      sx={{ marginTop: viewportMobile && theme.spacings.l, gap: theme.spacings.xs4 }}>
      <Button onClick={onSignIn} variant={'outlined'}>
        Zaloguj
      </Button>

      {viewportMobile && <span style={{ marginRight: `2rem` }}></span>}

      <Button onClick={onSignUp} variant={'contained'}>
        Zarejestruj się
      </Button>
    </List>
  );
};
