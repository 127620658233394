import { createTheme } from '@mui/material/styles';

/**
 * Represents value in pixels
 */
export type pixels = number;
/**
 * Transforms pixels integer into string
 * @param value number of pixels
 * @returns string for CSS
 */
export const px = (value: pixels): string => value + 'px';
export const evalPx = (value?: string | number) =>
  value ? (typeof value === 'string' ? value : px(value)) : undefined;

declare module '@mui/material/styles' {
  interface Theme {
    customColors: {
      [propName: string]: string;
    };
    boxShadows?: {
      [propName: string]: string;
    };
    boxShadowSpreads?: {
      [propName: string]: string;
    };
    overlays?: {
      [propName: string]: string;
    };
    border?: {
      [propName: string]: string | number;
    };
    borderRadius?: {
      [propName: string]: string | number;
    };
    customTransitionsDurations?: {
      [propName: string]: string;
    };
    customTransitionsEasings?: {
      [propName: string]: string;
    };
    fonts?: {
      [propName: string]: string;
    };
    fontWeights?: {
      [propName: string]: number;
    };
    paddings?: {
      [propName: string]: string;
    };
    zIndexes?: {
      [propName: string]: number;
    };
    spacings?: {
      [propName: string]: string;
    };
    sizes?: {
      [propName: string]: string;
    };
    shades?: {
      [propName: string]: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColors?: {
      [propName: string]: string;
    };
    boxShadows?: {
      [propName: string]: string;
    };
    boxShadowSpreads?: {
      [propName: string]: string;
    };
    overlays?: {
      [propName: string]: string;
    };
    border?: {
      [propName: string]: string | number;
    };
    borderRadius?: {
      [propName: string]: string | number;
    };
    customTransitionsDurations?: {
      [propName: string]: string;
    };
    customTransitionsEasings?: {
      [propName: string]: string;
    };
    fonts?: {
      [propName: string]: string;
    };
    fontWeights?: {
      [propName: string]: number;
    };
    paddings?: {
      [propName: string]: string;
    };
    zIndexes?: {
      [propName: string]: number;
    };
    spacings?: {
      [propName: string]: string;
    };
    sizes?: {
      [propName: string]: string;
    };
    shades?: {
      [propName: string]: string;
    };
  }
}

// Theme 1st layer

let theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
  fonts: {
    primary: 'Open Sans',
    // secondary: 'Playfair Display',
  },
  fontWeights: {
    regular: 400,
    bold: 600,
    black: 700,
  },
  customColors: {
    darkFont: '#000',
    primaryBrand: '#0069CC',
    primaryBrandDark: '#00519E',
    primaryBrandLight: '#0083FF',
    primaryBrand90: '#00376b',
    secondaryBrand: '#0047ee',
    shadesPrimaryBrand10: '#F5FAFF',
    shadesPrimaryBrand20: '#CCE6FF',
    shadesPrimaryBrand90: '#00376b33',
    shadesPrimaryBrand50: '#cc826b',
    colorsSecondaryBrand: '#221e1f',
    shadesSecondaryBrand30: '#d6d1d2',
    shadesSecondaryBrand70: '#808080',
    colorsInfo: '#0167ac',
    shadesInfo20: '#e1f3ff',
    positiveInfo: '#169B62',
    specialInfo: '#CA266F',
    shadesPositive20: '#E9FCF4',
    shadesPositive50: '#107047',
    shadesDark10: '#EEECED',
    error: '#E20015',
    errorDark: '#7A000B',
    errorLight: '#FFE0E3',
    lightGrey: '#B2B2B2',
    white: '#fff',
    darkBackground: '#000',
    semiTran70: 'rgb(255 255 255 / 72%)',
    semiTran90: 'rgb(255 255 255 / 96%)',
    orange: '#E16C2F',
    cancelRed: '#AD0010',
    darkGrey: '#333333',
    cartDark: '#01497A',
    cartBlack: '#1A1A1A',
  },
  overlays: {
    overlayImage: '#00519e14',
  },
  customTransitionsDurations: {
    shortest: '150ms',
    shorter: '200ms',
    short: '250ms',
    standard: '300ms',
    complex: '375ms',
    enteringScreen: '225ms',
    leavingScreen: '195ms',
  },
  customTransitionsEasings: {
    easeInOut: 'ease-in-out',
    // Objects enter the screen at full velocity from off-screen and
    // slowly decelerate to a resting point.
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    // Objects leave the screen at full velocity. They do not decelerate when off-screen.
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    // The sharp curve is used by objects that may return to the screen at any time.
    sharp: 'cubic-bezier(0.4, 0, 0.8, 1)',
    drawer: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },

  paddings: {
    sidePadding: '1rem',
    sidePadding900: '5%',
    sidePadding1200: '7.5%',
    sidePadding1536: '10%',
    buttonPaddingStandardY: '0.5em',
    buttonPaddingStandardX: '2em',
    buttonPaddingOutlinedX: '2em',
  },

  zIndexes: {
    zIndexPreloader: 10000,
    zIndexModal: 9999,
    zIndexHeader: 1000,
    zIndexMenuToggle: 9998,
    zIndexFront: 1,
    zIndexBehind: -1,
  },

  spacings: {
    xs8: px(2),
    xs6: px(4),
    xs5: px(8),
    xs4: px(16),
    xs3: px(24),
    xs2: px(32),
    xs1: px(36),
    xs: px(40),
    s: px(48),
    m: px(56),
    l: px(64),
    xl: px(80),
    xl2: px(96),
    xl3: px(128),
    xl4: px(192),
    xl5: px(240),
    xl6: px(296),
  },

  sizes: {
    selectMinWidth: px(200),
  },

  shades: {
    lightGrey: 'rgba(77, 77, 77, 0.16)',
    grey: 'rgba(26, 26, 26, 0.16)',
    primary: 'rgba(0, 105, 204, 0.16)',
    error: 'rgba(226, 0, 21, 0.16)',
    cardShadowGrey: 'rgba(128, 128, 128, 0.2)',
  },

  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
    unit: 'px',
  },

  boxShadowSpreads: {
    s: '0px 5px 10px',
    m: '4px 8px 16px',
    l: '0px 16px 50px',
  },
});

// Theme 2nd layer

theme = createTheme(theme, {
  palette: {
    mode: 'light',
    common: { black: '#000', white: '#fff' },
    primary: {
      main: theme.customColors.primaryBrand,
      light: theme.customColors.primaryBrandLight,
      dark: theme.customColors.primaryBrandDark,
      contrastText: theme.customColors.white,
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: theme.customColors.white,
    },
    error: {
      main: theme.customColors.error,
      light: theme.customColors.errorLight,
      dark: '#AD0010',
      contrastText: theme.customColors.white,
    },
    warning: {
      main: '#FFB511',
      light: '#FFF4DB',
      dark: '#DB9700',
      contrastText: theme.customColors.white,
    },
    info: {
      main: '#1B8DC6',
      light: theme.customColors.shadesPrimaryBrand20,
      dark: '#1674A2',
      contrastText: theme.customColors.white,
    },
    success: {
      main: '#1DC97F',
      light: theme.customColors.shadesPositive20,
      dark: theme.customColors.positiveInfo,
      contrastText: theme.customColors.white,
    },
    disabled: {
      main: theme.customColors.lightGrey,
    },
    grey: {
      '100': theme.customColors.shadesSecondaryBrand70,
      '200': '#eeeeee',
      '300': '#e6e6e6',
      '400': '#bdbdbd',
      '500': '#999999',
    },
  },
  boxShadows: {
    standard: `${theme.boxShadowSpreads.m} ${theme.customColors.shadesDark10}`,
    input: `${theme.boxShadowSpreads.m} ${theme.shades.lightGrey}`,
    inputHover: `${theme.boxShadowSpreads.m} ${theme.shades.grey}`,
    inputError: `${theme.boxShadowSpreads.m} ${theme.customColors.errorTrans}`,
    // select: `0px 5px 10px rgba(128, 128, 128, 0.2), 0px 16px 50px rgba(128, 128, 128, 0.2)`,
    // selectHovered: `box-shadow: 0px 5px 10px rgba(26, 26, 26, 0.2), 0px 16px 50px rgba(26, 26, 26, 0.2)`,
    // selectActive: `0px 5px 10px rgba(0, 105, 204, 0.2), 0px 16px 50px rgba(0, 105, 204, 0.2)`,
    list: `0px 5px 10px ${theme.customColors.shadesPrimaryBrand90}, 0px 16px 50px ${theme.customColors.shadesPrimaryBrand90}`,
    card: `${theme.boxShadowSpreads.s} ${theme.shades.cardShadowGrey}, 0px 16px 50px ${theme.shades.cardShadowGrey}`,
  },
  border: {
    table: `1px solid ${theme.palette.grey['300']}`,
  },
  borderRadius: {
    m: px(9),
    l: px(16),
  },
});

// Theme typography

theme = createTheme(theme, {
  typography: {
    h1: {
      fontSize: `clamp(26px, 4vw + 0.5rem, 28px)`,
      fontWeight: 700,
      margin: '0 0 1.6rem',
      color: theme.customColors.primaryBrandDark,
    },
    h2: {
      fontSize: 'clamp(22px, 4vw + 0.5rem, 24px)',
      fontWeight: 700,
      margin: '0 0 1.4rem',
    },
    h3: {
      fontSize: 'clamp(16px, 4vw + 0.5rem, 18px)',
      fontWeight: 700,
      margin: '0 0 1.4rem',
    },
    h4: {
      fontSize: 'clamp(22px, 4vw + 0.5rem, 28px)',
      fontWeight: 700,
      margin: '0 0 1.4rem',
    },
    h5: {
      margin: '0 0 1rem',
      fontSize: '10px',
    },
    h6: {
      fontSize: `10px`,
    },
  },
});

// global MenuClasses used in both MuiModal (mobile) and MuiDrawer (desktop)
const MenuClasses = {
  '& .MuiListItemButton-root': {
    color: theme.palette.primary.dark,

    a: {
      color: theme.palette.primary.dark,
      '&:hover': {
        color: theme.customColors.colorsSecondaryBrand,
      },
    },

    '&:hover': {
      backgroundColor: 'transparent!important',
    },

    '& .MuiListItemText-root': {
      color: 'currentColor',
    },

    '& .MuiListItemButton-root': {
      '> a': {
        color: theme.palette.grey['100'],
      },
    },

    '&.Mui-selected, & .Mui-selected': {
      color: theme.palette.error.dark,
      backgroundColor: 'transparent',

      a: {
        color: theme.palette.error.dark,
      },
    },
  },

  '& .MuiListItemButton-root.secondary': {
    color: theme.palette.grey['100'],
    a: {
      color: theme.palette.grey['100'],
      '&:hover': {
        color: theme.customColors.colorsSecondaryBrand,
      },
    },

    '&.Mui-selected, & .Mui-selected': {
      color: theme.palette.error.dark,

      a: {
        color: theme.palette.error.dark,
      },
    },
  },
};

// Theme components
// (Note: space or no space after `&` matters. See SASS "parent selector".)

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-gutterBottom': {
            marginBottom: 0,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: theme.customColors.darkFont,
          overflowWrap: 'break-word',
          alignItems: 'flex-start',
        },
        label: {
          paddingTop: '9px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&.MuiInputBase-input': {
            paddingRight: theme.spacings.xs1,
          },

          '&.Mui-selected': {
            backgroundColor: theme.customColors.shadesPrimaryBrand20,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: `${theme.paddings.buttonPaddingStandardY} ${theme.paddings.buttonPaddingStandardX}`,
          borderRadius: theme.borderRadius.m,
          fontFamily: theme.fonts.primary,
          fontWeight: theme.fontWeights.black,
          height: '100%',

          width: 'fit-content',

          '&.MuiButton-sizeSmall': {
            fontSize: '10px',
          },

          '&.MuiButton-containedPrimary': {
            boxShadow: `${theme.boxShadowSpreads.s} ${theme.shades.primary}, ${theme.boxShadowSpreads.l} ${theme.shades.primary}`,
            color: theme.customColors.white,
          },
          '&.MuiButton-negative': {
            backgroundColor: theme.customColors.error,
            boxShadow: `${theme.boxShadowSpreads.s} ${theme.shades.error}, ${theme.boxShadowSpreads.l} ${theme.shades.error}`,
            color: theme.customColors.white,

            '&:hover': {
              backgroundColor: theme.customColors.errorDark,
            },
          },
          '&.MuiButton-outlinedPrimary': {
            borderColor: `${theme.customColors.primaryBrand}`,
          },
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: theme.customColors.shadesPrimaryBrand20,
          '& .MuiChip-label': {
            fontSize: '1rem',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          boxShadow: `${theme.boxShadowSpreads.m} ${theme.shades.lightGrey}`,
          backgroundColor: theme.customColors.white,

          '&.MuiListItemText-root': {
            marginTop: '0',
            marginBottom: '0',
          },

          '&.MuiInputBase-multiline': {
            height: '100%',

            textarea: {
              height: '100%',

              /* width */
              '::-webkit-scrollbar': {
                width: '10px',
                borderRadius: theme.borderRadius.m,
              },

              /* Track */
              '::-webkit-scrollbar-track': {
                background: theme.shades.primary,
                borderRadius: theme.borderRadius.m,
              },

              /* Handle */
              '::-webkit-scrollbar-thumb': {
                background: theme.palette.primary.light,
                borderRadius: theme.borderRadius.m,
              },
            },
          },

          '&:hover': {
            boxShadow: `${theme.boxShadowSpreads.m} ${theme.shades.grey}`,
          },

          '&.Mui-focused': {
            boxShadow: `${theme.boxShadowSpreads.m} ${theme.shades.primary}`,
          },

          '&.Mui-error': {
            boxShadow: `${theme.boxShadowSpreads.m} ${theme.shades.error}`,
            color: theme.customColors.error,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiFormControl-root': {
            width: '100%',
          },
          '& .MuiInputAdornment-root svg': {
            fill: theme.customColors.lightGrey,
          },
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: theme.customColors.colorsInfo,
            },
            '&.Mui-error': {
              color: theme.customColors.error,
              borderColor: theme.customColors.error,
            },
          },
          '& .MuiFormLabel-filled': {
            color: theme.customColors.colorsSecondaryBrand,
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: theme.borderRadius.m,
            borderColor: theme.customColors.shadesSecondaryBrand30,

            '& input': {
              borderRadius: theme.borderRadius.m,
              borderColor: theme.customColors.shadesSecondaryBrand30,
            },

            '& fieldset': {
              borderColor: theme.customColors.shadesSecondaryBrand30,
              transition: 'all 0.3s ease-in-out',
            },
            '&:hover fieldset': {
              borderColor: theme.customColors.colorsSecondaryBrand,
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.customColors.colorsInfo,
              borderWidth: '1px',
            },
            '&.Mui-error fieldset': {
              color: theme.customColors.error,
              borderColor: theme.customColors.error,
            },
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            color: theme.customColors.error,
            borderColor: theme.customColors.error,

            '& svg': {
              fill: theme.customColors.error,
            },

            '& svg.MuiChip-deleteIconColorDefault': {
              fill: theme.customColors.shadesSecondaryBrand70,
            },
          },

          '& .MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: theme.palette.grey['300'],
            borderColor: theme.palette.grey['500'],
          },

          '& .MuiFormHelperText-root': {
            position: 'absolute',
            transform: 'translateY(100%)',
            fontSize: '10px',
            bottom: '-5px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.palette.grey['100'],
          '&.Mui-checked': {
            color: theme.customColors.primaryBrandDark,
          },
          '&.Mui-disabled': {
            color: theme.customColors.shadesSecondaryBrand30,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.grey['100'],
          '&.MuiRadio-root.Mui-checked': {
            color: theme.customColors.primaryBrandDark,
          },
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          color: theme.customColors.shadesPrimaryBrand20,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: theme.borderRadius.m,
          '&.MuiAlert-standardError': {
            color: theme.palette.error.dark,
            border: `1px solid ${theme.palette.error.dark}`,
            backgroundColor: theme.palette.error.light,
            '& svg': {
              fill: theme.palette.error.dark,
            },
          },
          '&.MuiAlert-standardInfo': {
            color: theme.palette.info.dark,
            border: `1px solid ${theme.palette.info.dark}`,
            backgroundColor: theme.palette.info.light,
            '& svg': {
              fill: theme.palette.info.dark,
            },
          },
          '&.MuiAlert-standardWarning': {
            color: theme.palette.warning.dark,
            border: `1px solid ${theme.palette.warning.dark}`,
            backgroundColor: theme.palette.warning.light,
            '& svg': {
              fill: theme.palette.warning.dark,
            },
          },
          '&.MuiAlert-standardSuccess': {
            color: theme.palette.success.dark,
            border: `1px solid ${theme.palette.success.dark}`,
            backgroundColor: theme.palette.success.light,
            '& svg': {
              fill: theme.palette.success.dark,
            },
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: theme.borderRadius.l,
            marginTop: theme.spacings.xs6,
            boxShadow: theme.boxShadows.list,
            '&::-webkit-scrollbar': {
              width: 0,
            },

            '& .MuiList-root': {
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme.customColors.shadesPrimaryBrand20,
              },
            },
          },
          ...MenuClasses,
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        root: {
          ...MenuClasses,
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-badge': {
            backgroundColor: `${theme.customColors.primaryBrand}`,
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          padding: '40px',
          boxShadow: theme.boxShadows.list,
          height: '100%',

          '& .MuiCardActions-root': {
            padding: 'initial',
          },

          '& .MuiCardContent-root': {
            padding: 'initial',
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            transition: `${theme.customTransitionsDurations.short} ${theme.customTransitionsEasings.easeInOut}`,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'initial',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '100px',
          width: '100%',

          '& .MuiOutlinedInput-root': {
            borderRadius: theme.borderRadius.m,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.customColors.error,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            borderBottom: `2px solid ${theme.palette.grey['400']}`,
            minWidth: `120px`,
            padding: '16px',

            p: {
              color: theme.palette.grey['500'],
              textTransform: 'uppercase',
              fontWeight: 700,
              fontSize: '12px',
              maxWidth: `110px`,
            },
          },
          '& .MuiTableCell-alignRight': {
            p: {
              marginLeft: 'auto',
            },
          },
          '& .MuiTableCell-alignLeft': {
            p: {
              marginRight: 'auto',
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          td: {
            padding: '14px 12px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#1A1A1A',
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          td: {
            borderBottom: `none`,
            padding: '16px',
          },
        },
      },
    },
  },
});

export default theme;
