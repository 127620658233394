import {Box, Checkbox, FormControlLabel, FormHelperText} from '@mui/material';
import {useEffect} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {SHORT_INPUT_LENGTH} from "../../../../utils/constans";
import PriceField from "../../../PriceField";

export default function ProductHalfPrice({index}) {
  const {
    control,
    unregister,
    formState: {
      errors
    }
  } = useFormContext();

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.multipleUsage`);
      unregister(`rewards.${index}.value`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.multipleUsage`}
        control={control}
        defaultValue={false}
        render={({field}) => {
          return (
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value}/>}
              label="Możliwość wielokrotnego zastosowania"
            />
          );
        }}
      />

      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={650}
        render={({field}) => {
          return (
            <Box maxWidth={SHORT_INPUT_LENGTH}>
              <PriceField
                field={field}
                label={'Próg zamówienia'}
                error={errors?.conditions?.[index]?.value}
              />
              <FormHelperText>
                <>{errors?.conditions?.[index]?.value?.message}</>
              </FormHelperText>
            </Box>
          );
        }}
      />

    </>);
}
