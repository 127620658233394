import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import PercentageField from 'components/PercentageField';
import PriceField from 'components/PriceField';
import QtyField from 'components/QtyField';
import { SingleSelectAutocomplete } from 'components/selectsAutocomplete/SingleSelectAutocomplete';
import { DiscountType } from 'features/SpecialOffers/specialOffersSlice';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SHORT_INPUT_LENGTH } from 'utils/constans';

export default function ExtraProduct({ index, chosenReward, productsData }) {
  const { control, unregister, formState, clearErrors } = useFormContext();

  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.value`);
      unregister(`rewards.${index}.valueDiscount`);
      unregister(`rewards.${index}.maxQty`);
      unregister(`rewards.${index}.minQty`);
      unregister(`rewards.${index}.maxQtyOfRepeats`);
      unregister(`rewards.${index}.valueType`);
      unregister(`rewards.${index}.productInCart`);
      unregister(`rewards.${index}.productToBeAddedToCart`);
    };
  }, [index, unregister]);

  const refreshVariant = () => {
    clearErrors(`rewards.${index}.value`);
    clearErrors(`rewards.${index}.valueDiscount`);
    unregister(`rewards.${index}.value`);
    unregister(`rewards.${index}.valueDiscount`);
  };

  return (
    <>
      <Controller
        name={`rewards.${index}.productInCart`}
        control={control}
        render={({ field }) => {
          return (
            <FormControl error={!!errors?.rewards?.[index]?.productInCart}>
              <SingleSelectAutocomplete
                {...field}
                uniqueKey={'sku'}
                label={'Produkt w koszyku'}
                data={(productsData && [...productsData.data]) || []}
                singleLabelParams={['name', 'sku']}
                error={!!errors?.rewards?.[index]?.productInCart}
              />
              <FormHelperText>
                <>{errors?.rewards?.[index]?.productInCart?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.minQty`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box maxWidth={120}>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Liczba min"
                  error={errors?.rewards?.[index]?.minQty}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.minQty?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.productToBeAddedToCart`}
        control={control}
        render={({ field }) => {
          return (
            <FormControl error={!!errors?.rewards?.[index]?.productToBeAddedToCart}>
              <SingleSelectAutocomplete
                {...field}
                uniqueKey={'sku'}
                label={'Produkt dodawany do koszyka w promocji'}
                data={(productsData && [...productsData.data]) || []}
                singleLabelParams={['name', 'sku']}
                error={!!errors?.rewards?.[index]?.productToBeAddedToCart}
              />
              <FormHelperText>
                <>{errors?.rewards?.[index]?.productToBeAddedToCart?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.maxQty`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box maxWidth={120}>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Liczba max"
                  error={errors?.rewards?.[index]?.maxQty}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.maxQty?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.valueType`}
        control={control}
        defaultValue={`${DiscountType.PROMO}`}
        render={({ field }) => (
          <FormControl onChange={() => refreshVariant()}>
            <RadioGroup aria-labelledby={field.name} {...field}>
              <Grid container direction="row">
                <FormControlLabel
                  value={`${DiscountType.PROMO}`}
                  control={<Radio />}
                  label="cena promocyjna"
                />
                <FormControlLabel
                  value={`${DiscountType.DISCOUNT}`}
                  control={<Radio />}
                  label="rabat"
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        name={`rewards.${index}.value`}
        defaultValue={1}
        control={control}
        render={({ field }) => {
          if (chosenReward?.[index]?.valueType === DiscountType.PROMO) {
            return (
              <FormControl>
                <Box>
                  <PriceField
                    field={field}
                    label={'Wartość'}
                    error={errors?.rewards?.[index]?.value}
                  />
                </Box>
                <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                  <>{errors?.rewards?.[index]?.value?.message}</>
                </FormHelperText>
              </FormControl>
            );
          }
        }}
      />

      <Controller
        name={`rewards.${index}.valueDiscount`}
        defaultValue={1}
        control={control}
        render={({ field }) => {
          if (chosenReward?.[index]?.valueType === DiscountType.DISCOUNT) {
            return (
              <FormControl>
                <Box>
                  <PercentageField
                    field={field}
                    label={'Wartość'}
                    error={errors?.rewards?.[index]?.valueDiscount}
                  />
                </Box>
                <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                  <>{errors?.rewards?.[index]?.valueDiscount?.message}</>
                </FormHelperText>
              </FormControl>
            );
          }
        }}
      />

      <Controller
        name={`rewards.${index}.maxQtyOfRepeats`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box maxWidth={SHORT_INPUT_LENGTH}>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Max liczba powtórzeń"
                  error={errors?.rewards?.[index]?.maxQtyOfRepeats}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.maxQtyOfRepeats?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
