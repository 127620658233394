export function moneyFormat(amount: number) {
  if (typeof amount === 'number' && !Number.isNaN(amount)) {
    const divided = amount / 100;
    return Number(divided.toFixed(2)).toFixed(2).replace('.', ',');
  } else {
    return '0,00';
  }
}

export function translateBoolean(value: boolean) {
  if (value === true) return 'TAK';
  return 'NIE';
}
