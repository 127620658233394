import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/redux/store';
import { ContactDataFormFields } from 'components/UserForms/ContactDataDashboardForm';
import { IStructure, IUser } from 'features/User/UserSlice';
import { baseBackendUrl } from 'utils/config';
import {IProduct} from "../Products/productsSlice";

export const UserApi = createApi({
  reducerPath: 'user',
  tagTypes: ['Users', 'User'],
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
    // credentials: 'include',
  }),
  endpoints: (builder) => ({
    editUserSelf: builder.mutation<IUser, ContactDataFormFields>({
      query(body) {
        return {
          url: `users/self`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'User', body },
          { type: 'Users', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    getUserSelf: builder.query<IUser, void>({
      query: () => `users/self`,
      providesTags: (result) => (result ? [{ type: 'User', id: result.id }] : []),
    }),
    getMyStructure: builder.query<IStructure<IUser>, void>({
      query: () => 'users/self-structure',
      providesTags: (result) => (result ? [{ type: 'Users', id: result.id }] : []),
    }),
    getNewUsers: builder.query<IUser[], number>({
      query: (id) => `users/new-users/${id}`,
    }),
    getMyStructureAsList: builder.query<IStructure<IUser>, void>({
      query: () => 'users/self-list',
      providesTags: (result) => (result ? [{ type: 'Users', id: result.id }] : []),
    }),
    getDiscountForPreviousMonth: builder.query<number, number>({
      query: (id) => `mlmProgress/discount/${id}`,
    }),
  }),
});

export default UserApi;
