import { SvgIconProps } from '@mui/material';
import React from 'react';

const LogoSVG = React.forwardRef<HTMLElement & SVGElement, SvgIconProps>(function LogoSVG(
  props,
  ref
) {
  return (
    <svg
      {...props}
      className="logo"
      width="100%"
      height="100%"
      viewBox="0 0 150 110"
      fill={props.fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M146.36 27.7959H128.925L132.315 10.3612H149.751L146.36 27.7959Z"
        fill="#E20015"
      />
      <path
        d="M145.821 17.1196H142.027L142.858 12.8454H138.832L138.001 17.1196H133.959L133.173 21.1587H137.215L136.339 25.6652H140.367L141.242 21.1587H145.036L145.821 17.1196Z"
        fill="white"
      />
      <path
        d="M29.0662 71.8711C28.8684 72.5836 28.6593 73.2792 28.4359 73.9571C40.6843 79.2829 56.0447 82.4537 72.7233 82.4537C86.7101 82.4537 99.7709 80.2233 110.847 76.3633C101.164 78.6206 90.3746 79.9492 78.9853 80.0908C59.9537 80.3272 42.5075 77.2114 29.0662 71.8711ZM2.16554 44.5176C1.91466 24.348 35.9025 7.5711 78.078 7.0475C96.9956 6.81257 114.346 9.88932 127.755 15.172C114.462 6.45472 94.7393 0.931288 72.7233 0.931288C32.6969 0.931288 0.25 19.1793 0.25 41.6929C0.25 51.4835 6.38939 60.4662 16.62 67.4975L16.9805 65.7808C7.75698 59.8706 2.26495 52.5294 2.16554 44.5176Z"
        fill="#00519E"
      />
      <path
        d="M38.752 20.2306H28.2304L17.8958 71.6138C16.7936 76.911 13.0125 84.1324 6.89062 84.4756L7.99121 87.5021C15.0648 87.4608 19.7079 85.0371 22.7872 80.7809C25.0006 77.7778 26.5073 73.839 27.6213 69.1011L38.752 20.2306Z"
        fill="#00519E"
      />
      <path
        d="M65.7979 65.0759H56.788L57.6128 61.4968C54.1062 63.835 49.9792 65.7623 45.7148 65.7623C38.2874 65.7623 38.3554 58.1255 39.4556 52.8313L43.5151 33.2993L52.8688 31.7846L48.6723 51.7984C48.0533 54.6866 47.3651 60.2591 51.7665 60.2591C54.1062 60.2591 56.5126 59.0893 58.3013 57.6466L63.3903 33.2993L72.7438 31.7846L65.7979 65.0759Z"
        fill="#00519E"
      />
      <path
        d="M92.6143 35.4982C89.7955 35.4289 86.2178 37.2163 86.2178 40.4506C86.2178 44.5762 98.9415 45.4705 98.9415 53.4477C98.8734 61.9082 90.0711 65.8306 82.7789 65.8306C79.6173 65.8306 76.798 65.0748 73.8397 64.0417L73.5654 60.2581C76.247 61.6326 79.3414 62.3208 82.2986 62.3208C85.6005 62.3208 90.0711 60.4642 90.0711 56.613C90.1388 51.0418 77.3465 50.8353 77.3465 43.4079C77.3465 35.843 85.8054 32.0593 92.4078 32.0593C94.6091 32.0593 97.7037 32.7459 99.6293 33.8479L99.8355 37.6302C97.6343 36.4605 95.1591 35.4982 92.6143 35.4982Z"
        fill="#00519E"
      />
      <path
        d="M127.001 35.6339H119.09L115.103 54.5471C114.277 58.3281 113.932 60.9434 119.022 60.9434C120.329 60.9434 121.636 60.8062 122.942 60.597L121.224 64.3825C117.923 65.072 114.621 65.7585 111.182 65.7585C105.198 65.7585 104.51 62.1809 105.542 57.3657L110.081 35.6339H105.956L106.574 32.6752H110.632L112.283 25.0412L121.568 23.5265L119.71 32.6752H127.551L127.001 35.6339Z"
        fill="#00519E"
      />
      <path
        d="M11.2405 102.304C9.56473 102.304 8.24902 103.271 8.24902 105.214C8.24902 107.157 9.56473 108.123 11.2405 108.123C12.9162 108.123 14.2319 107.157 14.2319 105.214C14.2319 103.271 12.9162 102.304 11.2405 102.304ZM11.2405 101.359C14.1909 101.359 15.3217 103.333 15.3217 105.214C15.3217 107.095 14.1909 109.069 11.2405 109.069C8.29016 109.069 7.15918 107.095 7.15918 105.214C7.15918 103.333 8.29016 101.359 11.2405 101.359Z"
        fill="#00519E"
      />
      <path
        d="M21.1918 104.827C22.2609 104.827 22.5179 104.18 22.5179 103.666C22.5179 102.967 22.0964 102.494 21.2227 102.494H18.1488V104.827H21.1918ZM17.0591 101.548H21.603C23.073 101.548 23.6075 102.566 23.6075 103.501C23.6075 104.396 23.1758 105.054 22.374 105.31V105.331C23.0216 105.423 23.402 106.03 23.4431 106.894C23.5048 108.261 23.5459 108.6 23.7926 108.888H22.6413C22.4665 108.682 22.4665 108.374 22.4151 107.49C22.3431 106.174 21.9011 105.773 20.9965 105.773H18.1488V108.888H17.0591V101.548Z"
        fill="#00519E"
      />
      <path d="M26.5169 101.548H25.4272V108.888H26.5169V101.548Z" fill="#00519E" />
      <path
        d="M32.7229 104.926H36.0948V108.884H35.3135L35.1284 107.835C34.6453 108.401 33.8639 109.069 32.3118 109.069C29.3716 109.069 28.436 106.951 28.436 105.337C28.436 102.5 30.3789 101.359 32.2809 101.359C33.7921 101.359 35.5088 101.831 35.9714 103.857H34.8201C34.4809 102.839 33.72 102.304 32.3323 102.304C30.3894 102.304 29.5257 103.682 29.5257 105.245C29.5257 107.321 30.8518 108.123 32.3837 108.123C33.9771 108.123 35.0154 107.177 35.1284 105.872H32.7229V104.926Z"
        fill="#00519E"
      />
      <path d="M39.3465 101.548H38.2568V108.888H39.3465V101.548Z" fill="#00519E" />
      <path
        d="M42.6875 103.049H42.6668V108.888H41.5771V101.548H42.8416L47.5604 107.387H47.5809V101.548H48.6705V108.888H47.406L42.6875 103.049Z"
        fill="#00519E"
      />
      <path
        d="M53.8718 102.555L52.4941 105.804H55.2802L53.8718 102.555ZM55.6811 106.75H52.0831L51.1475 108.888H49.9756L53.2962 101.548H54.54L57.8604 108.888H56.6166L55.6811 106.75Z"
        fill="#00519E"
      />
      <path
        d="M59.1675 101.548H60.2572V107.942H64.7188V108.888H59.1675V101.548Z"
        fill="#00519E"
      />
      <path d="M73.5483 105.497H69.9399V106.566H73.5483V105.497Z" fill="#00519E" />
      <path
        d="M79.9349 106.468C79.9554 107.784 81.1891 108.123 82.2993 108.123C83.461 108.123 84.2835 107.681 84.2835 106.879C84.2835 106.211 83.7591 105.933 82.9161 105.789L81.2508 105.512C80.3769 105.368 79.0712 105.018 79.0712 103.589C79.0712 102.253 80.1302 101.359 82.0115 101.359C83.4815 101.359 85.0338 101.893 85.1367 103.661H84.0469C83.9545 103.086 83.6256 102.304 81.8676 102.304C81.0655 102.304 80.1611 102.644 80.1611 103.507C80.1611 103.96 80.4592 104.319 81.3638 104.474L83.1629 104.782C83.7384 104.885 85.3732 105.162 85.3732 106.663C85.3732 108.534 83.6973 109.069 82.2172 109.069C80.5619 109.069 78.8452 108.493 78.8452 106.468H79.9349Z"
        fill="#00519E"
      />
      <path d="M88.0282 101.548H86.9385V108.888H88.0282V101.548Z" fill="#00519E" />
      <path
        d="M91.369 103.049H91.3485V108.888H90.2588V101.548H91.5231L96.2418 107.387H96.2623V101.548H97.352V108.888H96.0875L91.369 103.049Z"
        fill="#00519E"
      />
      <path
        d="M105.526 104.186C105.362 103.168 104.56 102.304 103.018 102.304C101.281 102.304 100.18 103.302 100.18 105.306C100.18 106.694 100.931 108.123 102.956 108.123C104.241 108.123 105.218 107.506 105.526 106.077H106.616C106.441 107.702 105.043 109.069 102.946 109.069C101.095 109.069 99.0908 108.051 99.0908 105.193C99.0908 103.055 100.427 101.359 102.935 101.359C104.406 101.359 106.431 101.986 106.616 104.186H105.526Z"
        fill="#00519E"
      />
      <path
        d="M108.386 101.548H114.287V102.494H109.476V104.622H114.143V105.568H109.476V107.942H114.39V108.888H108.386V101.548Z"
        fill="#00519E"
      />
      <path
        d="M121.338 103.697H119.189V102.895H119.436C121.266 102.895 121.533 102.031 121.575 101.548H122.428V108.888H121.338V103.697Z"
        fill="#00519E"
      />
      <path
        d="M127.669 102.454C126.426 102.454 125.87 103.184 125.87 104.058C125.87 104.932 126.426 105.662 127.669 105.662C128.924 105.662 129.479 104.932 129.479 104.058C129.479 103.184 128.924 102.454 127.669 102.454ZM126.055 107.204C126.169 107.862 126.785 108.19 127.484 108.19C128.666 108.19 129.53 107.707 129.653 105.579H129.633C129.355 106.052 128.574 106.525 127.536 106.525C126.292 106.525 124.781 105.949 124.781 104.078C124.781 102.999 125.562 101.591 127.628 101.591C129.808 101.591 130.682 102.927 130.682 105.179C130.682 108.92 128.42 109.054 127.433 109.054C126.569 109.054 125.223 108.694 125.027 107.204H126.055Z"
        fill="#00519E"
      />
      <path
        d="M134.383 104.675C134.517 104.685 134.671 104.685 135.072 104.685C135.997 104.685 136.47 104.284 136.47 103.636C136.47 102.732 135.679 102.454 134.908 102.454C133.818 102.454 133.314 102.999 133.263 103.955H132.235C132.317 102.32 133.407 101.591 134.938 101.591C136.779 101.591 137.56 102.547 137.56 103.554C137.56 104.212 137.231 104.839 136.491 105.024V105.045C137.303 105.189 137.879 105.847 137.879 106.762C137.879 107.995 137.025 109.054 135.082 109.054C134.404 109.054 132.05 109.054 132.05 106.628H133.078C133.139 107.841 133.9 108.19 134.99 108.19C136.141 108.19 136.789 107.646 136.789 106.833C136.789 106.001 136.049 105.548 134.99 105.548C134.62 105.548 134.486 105.569 134.383 105.569V104.675Z"
        fill="#00519E"
      />
      <path
        d="M142.571 102.454C140.813 102.454 140.69 104.469 140.69 105.322C140.69 106.176 140.813 108.19 142.571 108.19C144.328 108.19 144.453 106.176 144.453 105.322C144.453 104.469 144.328 102.454 142.571 102.454ZM142.571 101.591C144.832 101.591 145.543 103.277 145.543 105.322C145.543 107.368 144.832 109.054 142.571 109.054C140.309 109.054 139.6 107.368 139.6 105.322C139.6 103.277 140.309 101.591 142.571 101.591Z"
        fill="#00519E"
      />
    </svg>
  );
});

export default LogoSVG;
