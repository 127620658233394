import { css } from '@emotion/react';
import theme from 'styles/theme';

const ReactDatePickerStyles = css`
  .css-specificity-wrapper--date-picker {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    background: ${theme.customColors.white};

    .react-date-picker,
    .react-datetime-picker {
      width: 100%;
      background: ${theme.customColors.white};

      &__clear-button {
        svg {
          stroke: ${theme.palette.error.main};
        }
      }

      &__wrapper {
        width: 100%;
        cursor: pointer;
        border-color: #d7d1d2;
        height: auto;
        min-height: 1.4375em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 16.5px 14px;

        border-radius: ${theme.borderRadius.m};
        box-shadow: ${theme.boxShadowSpreads.m} ${theme.shades.lightGrey};

        &:hover {
          border-color: ${theme.palette.common.black};
        }
      }
      &__inputGroup__input:invalid {
        background: ${theme.customColors.shadesPrimaryBrand10};
      }
    }

    abbr {
      text-decoration: none;
    }

    .react-calendar {
      font-family: inherit;
    }

    .react-calendar__navigation__arrow {
      font-size: 24px;
    }

    .react-calendar__navigation__label {
      font-size: 16px;
    }

    .react-calendar__tile--now {
      background-color: #cde6ff;
    }
  }

  .error {
    .css-specificity-wrapper--date-picker {
      .react-date-picker,
      .react-datetime-picker {
        &__wrapper {
          border-color: ${theme.palette.error.main};

          input,
          span {
            color: ${theme.palette.error.main};
          }

          svg {
            fill: ${theme.palette.error.main};
          }
        }
      }
    }
  }
`;

export default ReactDatePickerStyles;
