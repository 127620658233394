import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { MenuProps } from 'components/selects/settings';
import ProductsSlice from 'features/Products/productsSlice';
import {
  ISpecialOffer,
  rewardsAvailableVariants,
  RewardType,
} from 'features/SpecialOffers/specialOffersSlice';
import { useState, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import ContainerLightBlueBg from 'components/ContainerLightBlueBg';
import theme from 'styles/theme';
import Coupon from './repeaterFields/Coupon';
import DaysNumber from './repeaterFields/DaysNumber';
import DiscountOrder from './repeaterFields/DiscountOrder';
import DiscountProduct from './repeaterFields/DiscountProduct';
import DiscountShipping from './repeaterFields/DiscountShipping';
import ExtraProduct from './repeaterFields/ExtraProduct';
import FreeProduct from './repeaterFields/FreeProduct';
import MonthsNumber from './repeaterFields/MonthsNumber';
import ProductHalfPrice from './repeaterFields/ProductHalfPrice';
import ProductSpecialPrice from './repeaterFields/ProductSpecialPrice';
import ProductTurnover from './repeaterFields/ProductTurnover';
import ProductsFromCategory from './repeaterFields/ProductsFromCategory';

export const getRewardName = (
  rewardType: typeof rewardsAvailableVariants[number]['type']
) => {
  let rewardName = '';

  rewardsAvailableVariants.map((reward) => {
    if (reward.type === rewardType) {
      return (rewardName = reward.name);
    }
    return;
  });

  return rewardName;
};

export default function RepeaterCondition() {
  const { control, formState } = useFormContext<ISpecialOffer>();

  const { errors } = formState;

  const [queryArgs, setQueryArgs] = useState({
    limit: null,
    offset: 0,
    order: '',
    search: '',
    sort: '',
  });

  //Products RTK query
  const { useGetAllAdminQuery: useGetFilteredProductsQuery } = ProductsSlice;
  const { data: rawProductsData } = useGetFilteredProductsQuery({
    ...queryArgs,
    order: 'ASC',
    sort: 'name',
  });

  const productsData = useMemo(() => {
    return rawProductsData
      ? {
          ...rawProductsData,
          data: rawProductsData.data.filter(product => product.available > 0).map((product) => {
            return { ...product, name: `${product.name}` };
          }),
        }
      : null;
  }, [rawProductsData]);

  // const availableProductsData = productsData.filter(product => product.available > 0);

  const chosenReward = useWatch<ISpecialOffer, 'rewards'>({
    control,
    name: 'rewards',
  });

  const { fields, append, remove } = useFieldArray<ISpecialOffer, 'rewards', 'id'>({
    control,
    name: 'rewards',
    keyName: 'id',
  });

  const removeElement = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          gap: theme.spacings.xs3,
        }}>
        {fields.map((item, index) => {
          return (
            <ContainerLightBlueBg key={item.id}>
              <Grid
                container
                flexDirection={'column'}
                mb={theme.spacings.xs2}
                sx={{
                  gap: 5,
                  maxWidth: '800px',
                }}>
                <Controller
                  name={`rewards.${index}.type`}
                  control={control}
                  render={({ field }) => (
                    <Box ref={field.ref} tabIndex={0}>
                      <FormControl error={!!errors?.rewards?.[index]}>
                        <InputLabel>Nagroda</InputLabel>
                        <Select
                          label="Nagroda"
                          {...field}
                          value={field.value || ''}
                          IconComponent={KeyboardArrowDownIcon}
                          MenuProps={MenuProps}>
                          {rewardsAvailableVariants.map((option, i) => (
                            <MenuItem key={i} value={option.type}>
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          <>
                            {
                              //@ts-ignore
                              errors?.rewards?.[index]?.type?.message
                            }
                          </>
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  )}
                />

                {chosenReward?.[index]?.type === RewardType.FREE_PRODUCT ? (
                  <FreeProduct productsData={productsData} index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.PRODUCT_SPECIAL_PRICE ? (
                  <ProductSpecialPrice productsData={productsData} index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.DISCOUNT_PRODUCT ? (
                  <DiscountProduct productsData={productsData} index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.EXTRA_PRODUCT ? (
                  <ExtraProduct
                    productsData={productsData}
                    chosenReward={chosenReward}
                    index={index}
                  />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.DISCOUNT_ORDER ? (
                  <DiscountOrder index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.DISCOUNT_SHIPPING ? (
                  <DiscountShipping chosenReward={chosenReward} index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.COUPON ? (
                  <Coupon chosenReward={chosenReward} index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.PRODUCT_TURNOVER ? (
                  <ProductTurnover index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.PRODUCT_HALF_PRICE ? (
                  <ProductHalfPrice index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.MONTHS_NUMBER ? (
                  <MonthsNumber index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.DAYS_NUMBER ? (
                  <DaysNumber index={index} />
                ) : null}

                {chosenReward?.[index]?.type === RewardType.PRODUCTS_FROM_CATEGORY ? (
                  <ProductsFromCategory index={index} />
                ) : null}
              </Grid>

              <Box sx={{ marginLeft: 'auto' }}>
                <Button
                  variant={'contained'}
                  color={'error'}
                  type="button"
                  onClick={() => removeElement(index)}>
                  Usuń nagrodę
                </Button>
              </Box>
            </ContainerLightBlueBg>
          );
        })}
      </Grid>

      <Box>
        <Button
          variant={'outlined'}
          type="button"
          onClick={() => append({ id: fields.length })}>
          Dodaj nagrodę
        </Button>
      </Box>
    </>
  );
}
