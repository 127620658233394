import Dialog from '@mui/material/Dialog';

type SimpleDialogProps = {
  open: boolean;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClose: () => void;
  children: React.ReactNode;
};

export default function DialogMUI(props: SimpleDialogProps) {
  const { open, maxWidth, onClose, children } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          padding: '40px 80px',
        },
      }}>
      {children}
    </Dialog>
  );
}
