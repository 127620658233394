import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/redux/store';
import {baseBackendUrl} from 'utils/config';
import {Paginated} from "../types/paginatedQuery";
import {IFilter} from "../types/filters";
import {IUser} from "../User/UserSlice";

type IBilling = {
    id: number;
    user: IUser;
    amount: number;
    baseAmount: number;
    withActiveProvision: boolean;
    period: string;
    createdAt: Date;
    deletedAt: Date;
}

export const BillingsApi = createApi({
    reducerPath: 'billings',
    tagTypes: ['Billings', 'Billing'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseBackendUrl}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).userSlice?.userSessionData?.token;

            // If we have a token set in state, let's assume that we should be passing it.

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        calculateBillings: builder.mutation<any, any>({
            query() {
                return {
                    url: `billings/calculate`,
                    method: 'POST'
                };
            }
        }),
        payout: builder.mutation<any, any>({
            query({ids}) {
                return {
                    url: `billings/payout`,
                    method: 'POST',
                    body: {
                        ids
                    },
                };
            }
        }),
        recalculate: builder.mutation<any, any>({
            query({ids, percentage}) {
                return {
                    url: `billings/recalculate`,
                    method: 'POST',
                    body: {
                        ids,
                        percentage
                    },
                };
            }
        }),
        getFiltered: builder.query<Paginated<IBilling>, IFilter>({
            query: (filters: IFilter) =>
                `billings?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`
        }),
    }),
});

export default BillingsApi;
