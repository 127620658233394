import LogoSVG from 'components/icons/LogoSVG';
import Link from 'next/link';
import { FC } from 'react';

const LogoLink: FC = () => (
  <div className="image-holder logo-holder flex items-start">
    <Link href="/pulpit" passHref>
      <a>
        <LogoSVG />
      </a>
    </Link>
  </div>
);
export default LogoLink;
