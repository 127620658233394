import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IFilter } from 'features/types/filters';
import { Paginated } from 'features/types/paginatedQuery';
import { ISearch } from 'features/types/search';
import { baseBackendUrl } from 'utils/config';
import { IOrder } from './orderDetailsSlice';
import { RootState } from 'app/redux/store';
import {IProduct} from "../Products/productsSlice";
import {IRole} from "../User/UserSlice";

export const ordersApi = createApi({
  reducerPath: 'orders',
  tagTypes: ['Orders', 'Order'],
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllOrders: builder.query<Paginated<IOrder>, void>({
      query: () => 'orders',
      providesTags: [{ type: 'Orders', id: 'LIST' }],
    }),
    getFiltered: builder.query<Paginated<IOrder>, any>({
      query: ({filters, status, payment, delivery}) => {
        return `orders?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}&mainStatus=${status}&payment=${payment}&delivery=${delivery}`;
      }
    }),
    getFilteredCSV: builder.query<Paginated<IOrder>, any>({
      query: ({filters, status, payment, delivery}) => {
        return `orders/csv?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}&mainStatus=${status}&payment=${payment}&delivery=${delivery}`;
      },
    }),
    findOrders: builder.query<Paginated<IOrder>, ISearch>({
      query: (search) =>
        `orders/search?order=ASC&offset=0&limit=10&search[phrase][value]=${
          search.phrase
        }&search[categories][value]=${[...search.categories]}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Orders' as const, id })),
              { type: 'Orders', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Orders', id: 'PARTIAL-LIST' }],
    }),
    getOrder: builder.query<IOrder, number>({
      query: (id) => `orders/${id}`,
      providesTags: (result) => (result ? [{ type: 'Order', id: result.id }] : []),
    }),
    getDPDLabel: builder.query<any, string>({
      query: (id) => `orders/get-dpd-label/${id}`,
    }),
    getAdminData: builder.query<any[], {end: string, start: string, products: IProduct[], roles: IRole[]}>({
      query: (body) => {
        return {
          url: `orders/get-admin-data`,
          method: 'POST',
          body
        }
      }
    }),
    getPackageDocument: builder.query<{ document: string }, string>({
      query: (id) => `orders/get-package-pdf/${id}`,
    }),
    getWzDocument: builder.query<{ wz: string }, string>({
      query: (id) => `orders/get-wz-pdf/${id}`,
    }),
    getInvoice: builder.query<{ invoice: string }, string>({
      query: (id) => `orders/get-invoice-pdf/${id}`,
    }),
    getPossibleToPack: builder.query<Array<IOrder>, number | string>({
      query: (id) => `orders/possible-to-pack/${id}`,
      //providesTags: [{ type: 'Orders', id: 'LIST' }],
    }),
    addOrder: builder.mutation<any, any>({
      query(body) {
        return {
          url: `orders`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'Order', id },
          { type: 'Orders', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    syncSubiekt: builder.mutation<any, any>({
      query({ id }) {
        return {
          url: `orders/subiekt/${id}`,
          method: 'PUT',
        };
      }
    }),
    editOrder: builder.mutation<any, any>({
      query({ id, ...body }) {
        return {
          url: `orders/${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'Order', id },
          { type: 'Orders', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    getInvoices: builder.mutation<any, any>({
      query(ids) {
        return {
          url: `orders/get-invoices`,
          method: 'POST',
          body: ids,
        };
      }
    }),
    getSpecifications: builder.mutation<any, any>({
      query(ids) {
        return {
          url: `orders/get-specifications`,
          method: 'POST',
          body: ids,
        };
      }
    }),
    createDPDProtocol: builder.mutation<any, any>({
      query(ids) {
        return {
          url: `orders/get-dpd-protocols`,
          method: 'POST',
          body: ids,
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'Order', id },
          { type: 'Orders', id: 'PARTIAL-LIST' },
        ];
      },
    }),
    manyUpdate: builder.mutation<any, any>({
      query({ids, status}) {
        return {
          url: `orders/update-many`,
          method: 'POST',
          body: {
            ids, status
          },
        };
      }
    }),
    setAsPaid: builder.mutation<any, any>({
      query({ids}) {
        return {
          url: `orders/set-as-paid`,
          method: 'POST',
          body: {
            ids
          },
        };
      }
    }),
    manySubiektSync: builder.mutation<any, any>({
      query({ids}) {
        return {
          url: `orders/many-subiekt-sync`,
          method: 'POST',
          body: {
            ids
          },
        };
      }
    }),
    getDPDLabels: builder.mutation<any, any>({
      query(ids) {
        return {
          url: `orders/get-dpd-labels`,
          method: 'POST',
          body: ids,
        };
      },
    }),
    createDPDPackage: builder.mutation<any, any>({
      query({ id, ...body }) {
        return {
          url: `/orders/send-to-dpd/${id}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    deleteManyOrders: builder.mutation<IOrder[], number[]>({
      query(body) {
        return {
          url: `orders/`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: (result, error, body) => {
        return [
          { type: 'Order', body },
          { type: 'Orders', body: 'PARTIAL-LIST' },
        ];
      },
    }),
    deleteOneOrder: builder.mutation<IOrder, number>({
      query(id) {
        return {
          url: `orders/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [
          { type: 'Order', id },
          { type: 'Orders', id: 'PARTIAL-LIST' },
        ];
      },
    }),
  }),
});

export default ordersApi;
