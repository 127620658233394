export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      padding: 16,
      maxHeight: 800,
    },
  },
};
