import { ThunkAction, Action } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Reducer } from 'redux';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { menuSlice } from 'layout/Menu/menuSlice';
import { cartSlice } from 'features/Cart/cartSlice';
import { BillingsApi } from 'features/Billings/BillingsApi';
import { domElementsSlice } from 'features/DomElements/domElementsSlice';
import { RESET_STATE_ACTION_TYPE } from './actions/resetState';
import { userSlice } from 'features/User/UserSlice';
import { configSlice } from 'features/Config/configSlice';
import ProductsSlice from 'features/Products/productsSlice';
import FilesSlice from 'features/Files/filesSlice';
import CategoriesSlice from 'features/Categories/catogoriesSlice';
import SpecialOffersSlice from 'features/SpecialOffers/specialOffersSlice';
import RolesSlice from 'features/Roles/rolesSlice';
import VirtualWalletTransactionsSlice from 'features/VirtualWalletTransactions/virtualWalletTransactionsSlice';
import UserApi from 'features/User/UserApi';
import UsersApi from 'features/Users/UsersApi';
import debounce from 'lodash.debounce';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { orderDetailsSlice } from 'features/Orders/orderDetailsSlice';
import ordersApi from 'features/Orders/ordersApi';
import RoleProgressApi from "../../features/RoleProgress/RoleProgressApi";
import NewsApi from "../../features/News/NewsApi";
import VoucherApi from "../../features/Vouchers/VoucherApi";

const reducers = {
  userSlice: userSlice.reducer,
  configSlice: configSlice.reducer,
  menuSlice: menuSlice.reducer,
  cartSlice: cartSlice.reducer,
  orderDetailsSlice: orderDetailsSlice.reducer,
  domElementsSlice: domElementsSlice.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [UsersApi.reducerPath]: UsersApi.reducer,
  [ProductsSlice.reducerPath]: ProductsSlice.reducer,
  [FilesSlice.reducerPath]: FilesSlice.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [CategoriesSlice.reducerPath]: CategoriesSlice.reducer,
  [SpecialOffersSlice.reducerPath]: SpecialOffersSlice.reducer,
  [RolesSlice.reducerPath]: RolesSlice.reducer,
  [VirtualWalletTransactionsSlice.reducerPath]: VirtualWalletTransactionsSlice.reducer,
  [BillingsApi.reducerPath]: BillingsApi.reducer,
  [RoleProgressApi.reducerPath]: RoleProgressApi.reducer,
  [NewsApi.reducerPath]: NewsApi.reducer,
  [VoucherApi.reducerPath]: VoucherApi.reducer,
};

const combinedReducers = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      UserApi.middleware,
      BillingsApi.middleware,
      RoleProgressApi.middleware,
      UsersApi.middleware,
      RolesSlice.middleware,
      ProductsSlice.middleware,
      FilesSlice.middleware,
      CategoriesSlice.middleware,
      SpecialOffersSlice.middleware,
      VirtualWalletTransactionsSlice.middleware,
      ordersApi.middleware,
      NewsApi.middleware,
      VoucherApi.middleware,
    ),
  devTools: true,
  preloadedState: {
    cartSlice: loadStateFromLocalStorage('cart'),
    orderDetailsSlice: loadStateFromLocalStorage('orderDetails'),
    userSlice: loadStateFromLocalStorage('user'),
  },
});

// save initial state from orderDetails slice to localStorage to prevent it from being completely empty at preloaded state
saveStateToLocalStorage('orderDetails', store.getState().orderDetailsSlice);

// here we subscribe to the store changes
store.subscribe(
  debounce(() => {
    saveStateToLocalStorage('cart', store.getState().cartSlice);
    saveStateToLocalStorage('orderDetails', store.getState().orderDetailsSlice);
    saveStateToLocalStorage('user', store.getState().userSlice);
  }, 300)
);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

// export const persistor = persistStore(store);

export type RootState = ReturnType<typeof combinedReducers>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
