import styled from '@emotion/styled';
import React from 'react';
import theme from 'styles/theme';

export interface DashboardProps {
  children: React.ReactNode;
  dashboardContentBg?: string;
}

const DashboardPageContainer = styled.div`
  .dashboard {
    &__content {
      display: flex;
      flex-direction: column;
      padding: ${theme.spacings.xs} ${theme.spacings.xs};
      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        border-radius: ${theme.borderRadius.m};
      }
    }
  }
`;

export const DashboardPageContent: React.FC<DashboardProps> = ({
  children,
  dashboardContentBg,
}) => {
  return (
    <DashboardPageContainer className="dashboard full-w">
      <div
        className="dashboard__content full-h"
        style={{
          backgroundColor: dashboardContentBg
            ? dashboardContentBg
            : theme.customColors.white,
        }}
      >
        {children}
      </div>
    </DashboardPageContainer>
  );
};

export default DashboardPageContent;
