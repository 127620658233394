import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

interface Hamburger {
  open: boolean;
}

export const StyledBurger = styled('div')<Hamburger>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  width: '1.5rem',
  height: '1.5rem',

  div: {
    width: '1.5rem',
    height: '3px',
    background: theme.palette.grey['100'],
    borderRadius: '10px',
    transition: 'all 0.3s linear',
    position: 'relative',
    transformOrigin: '1px',

    '&:first-of-type': {
      transform: open ? 'rotate(45deg)' : 'rotate(0)',
    },
    '&:nth-of-type(2)': {
      opacity: open ? '0' : '1',
      transform: open ? 'translateX(20px)' : 'translateX(0)',
    },
    '&:nth-of-type(3)': {
      transform: open ? 'rotate(-45deg)' : 'rotate(0)',
    },
  },
}));

export const Hamburger: FC<Hamburger> = ({ open }) => {
  return (
    <StyledBurger open={open}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Hamburger;
