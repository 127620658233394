export const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

export const displaySingleLabelForOption = (params, option) => {
  const dynamicLabelParams = [];
  params.forEach((param) => {
    // handle unique label formatting
    if (param === 'sku') {
      dynamicLabelParams.push(` (SKU:${get(option, param)})`);
      return;
    }
    if (param === 'externalId') {
      dynamicLabelParams.push(`(${get(option, param)})`);
      return;
    }
    if (param === 'grossPrice') {
      dynamicLabelParams.push(`- Cena standardowa: ${get(option, param) / 100} PLN`);
      return;
    }

    if (param === 'location_description') {
      dynamicLabelParams.push(` - (${get(option, param)})`);
      return;
    }

    if (param === 'person_information') {
      dynamicLabelParams.push(`, `)
      option.city && dynamicLabelParams.push(`${get(option, 'city')}, `)
      option.address_1 && dynamicLabelParams.push(`${get(option, 'address_1')} `)
      option.address_2 && dynamicLabelParams.push(`${get(option, 'address_2')}, `)
      option.phone && dynamicLabelParams.push(`${get(option, 'phone')}, `)
      option.email && dynamicLabelParams.push(`${get(option, 'email')}`)
      return;
    }

    if (param === 'id') {
      dynamicLabelParams.push(` [${get(option, param)}]`);
      return;
    }

    dynamicLabelParams.push(get(option, param));
  });

  const dynamicLabel = dynamicLabelParams.join(' ');

  return dynamicLabel;
};
