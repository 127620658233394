import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Paginated } from 'features/types/paginatedQuery';
import { baseBackendUrl } from 'utils/config';
import { RootState } from 'app/redux/store';
import { IUser } from 'features/User/UserSlice';
import { IFilter } from 'features/types/filters';

export enum VirtualWalletTransactionType {
  ORDER = 'ORDER',
  ADD = 'ADD',
  SUB = 'SUB',
  PAYOUT = 'PAYOUT',
  PERIOD = 'PERIOD',
}

export enum VirtualWalletTransactionStatus {
  WAITING = 'WAITING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export interface IVirtualWallet {
  id: number;
  amount: number;
  //user?: IUser;
}

export interface IVirtualWalletTransaction {
  id: number;
  virtualWallet: IVirtualWallet;
  title: string;
  amount: number;
  balance: number;
  type: VirtualWalletTransactionType;
  status: VirtualWalletTransactionStatus;
  executor: IUser;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

const virtualWalletTransactionsSlice = createApi({
  reducerPath: 'virtualWalletTransactions',
  baseQuery: fetchBaseQuery({
    baseUrl: baseBackendUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Transactions'],
  endpoints: (builder) => ({
    getAllTransactions: builder.query<
      Paginated<IVirtualWalletTransaction>,
      { filters: IFilter }
    >({
      query: ({ filters }) =>
        `virtual-wallet-transactions?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Transactions' as const, id })),
              { type: 'Transactions', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Transactions', id: 'PARTIAL-LIST' }],

      keepUnusedDataFor: 0,
    }),
    getAllUserTransactions: builder.query<
      Paginated<IVirtualWalletTransaction>,
      { filters: IFilter; id: number }
    >({
      query: ({ filters, id }) =>
        `virtual-wallet-transactions/user/${id}?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Transactions' as const, id })),
              { type: 'Transactions', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Transactions', id: 'PARTIAL-LIST' }],

      keepUnusedDataFor: 0,
    }),
    getAllUserSelfTransactions: builder.query<
      Paginated<IVirtualWalletTransaction>,
      { filters: IFilter }
    >({
      query: ({ filters }) =>
        `virtual-wallet-transactions/self?order=ASC&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=createdAt`,
    }),
    getTransaction: builder.query<IVirtualWalletTransaction, number>({
      query: (id) => `virtual-wallet-transactions/${id}`,
      providesTags: (result) => (result ? [{ type: 'Transactions', id: result.id }] : []),
    }),
    addTransaction: builder.mutation<IVirtualWalletTransaction, any>({
      query({ id, body }) {
        return {
          url: `virtual-wallet-transactions/${id}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Transactions'],
    }),
    addPayout: builder.mutation<IVirtualWalletTransaction, any>({
      query({ body }) {
        return {
          url: `virtual-wallet-transactions/create-payout`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Transactions'],
    }),
    confirmPayout: builder.mutation<{ id: number; body: { title: string } }, any>({
      query({ id, body }) {
        return {
          url: `virtual-wallet-transactions/confirm-payout/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Transactions'],
    }),
  }),
});

export default virtualWalletTransactionsSlice;
