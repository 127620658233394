import { createSlice } from '@reduxjs/toolkit';
import { AppState } from 'app/redux/store';
import { IProduct } from 'features/Products/productsSlice';
import { IUser } from 'features/User/UserSlice';
import { clearLocalStorage } from 'utils/localStorage';
import {IUsersSpecialOfferReward} from "../SpecialOffers/specialOffersSlice";

export enum PaymentDocument {
  RECEIPT = 'RECEIPT',
  INVOICE = 'INVOICE',
}

export enum BillingStatus {
  NEW = 'NEW',
  PAID = 'PAID',
}

export enum ShippingType {
  DPD = 'DPD',
  INPOST = 'INPOST',
  LOCAL_PICKUP = 'LOCAL_PICKUP',
}

export enum PaymentType {
  COD = 'COD',
  P24 = 'P24',
  CASH = 'CASH',
  BANK = 'BANK',
}

export enum DeliveryStatus {
  NOT_SENT_TO_DPD = 'NOT_SENT_TO_DPD',
  PACKAGE_CREATED = 'PACKAGE_CREATED',
  DPD_ERROR = 'DPD_ERROR',
}

export enum PaymentStatus {
  ORDER_CREATED = 'ORDER_CREATED',
  REGISTERED = 'REGISTERED',
  NOTIFICATION_FAILED = 'NOTIFICATION_FAILED',
  REGISTRATION_FAILED = 'REGISTRATION_FAILED',
  CONFIRMED = 'CONFIRMED',
  CONFIRMATION_FAILED = 'CONFIRMATION_FAILED',
  CANCELED = 'CANCELED',
}

export enum SubiektStatus {
  IN_SYNC = 'IN_SYNC',
  NOT_IN_SYNC = 'NOT_IN_SYNC',
  CRITICAL_ERROR = 'CRITICAL_ERROR',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  PRODUCT_OUT_OF_STOCK = 'PRODUCT_OUT_OF_STOCK',
  DISABLED = 'DISABLED',
}

export enum OrderStatus {
  NEW = 'NEW',
  READY_FOR_PACKING = 'READY_FOR_PACKING',
  PACKED = 'PACKED',
  INVOICED = 'INVOICED',
  SENT = 'SENT',
  CANCELED_BEFORE_INVOICE = 'CANCELED_BEFORE_INVOICE',
  CANCELED_AFTER_INVOICE = 'CANCELED_AFTER_INVOICE',
  RETURNED = 'RETURNED',
}

export enum OrderProductType {
  REGULAR = 'REGULAR',
  REWARD = 'REWARD',
  VOUCHER = 'VOUCHER',
}


export interface IOrderDetails {
  buyer?: Partial<IUser>;
  id: number;
  paymentDocument: PaymentDocument;
  paymentDocumentNumber?: string;
  orderDocumentNumber?: string;
  stockIssueConfirmationDocument?: string;
  stockIssueConfirmationDocumentNumber?: string;
  stockIssueConfirmationDocumentVoucher?: string;
  returnDocumentNumber?: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  companyName: string;
  companyTaxNumber: string;
  address_1: string;
  address_2: string;
  address_3: string;
  postalCode: any;
  city: string;
  shipToDifferentAddress: boolean;
  shippingFirstName: string;
  shippingLastName: string;
  shippingAddress_1: string;
  shippingAddress_2: string;
  shippingAddress_3: string;
  shippingPostalCode: any;
  shippingCity: string;
  saveShippingAddress: boolean;
  shippingType: ShippingType;
  deliveryNotes: string;
  chosenParcelLocker?: string;
  paymentType: PaymentType;
  clientNotes: string;
  adminNotes?: string;
  walletDiscount: number;
  userAcceptedRules: boolean;
  userAcceptedMarketingConsents: boolean;
  userAcceptedCommercialInfo: boolean;
}

export interface IPaymentData {
  id: 1;
  p24OrderId: null;
  p24Statement: null;
  token: string;
  paymentId: null;
  paymentStatus: PaymentStatus;
  paymentType: PaymentType;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDeliveryData {
  id: number;
  dpdSessionId: null;
  dpdPackageId: null;
  dpdWaybillId: null;
  status: null;
  parcels: IDPDParcel[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export interface IDPDParcel {
  id?: number;
  dpdParcelId?: string;
  dpdWaybillId?: string;
  dpdStatus?: string;
  weight: number;
  sizeX: number;
  sizeY: number;
  sizeZ: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface IOrderProduct {
  id?: number;
  product: IProduct;
  quantity: number;
  subtotal: number;
}

export interface IApiOrderProduct {
  id: number;
  type: OrderProductType;
  order: IOrder;
  position: number;
  quantity: number;
  product: IProduct;
  //subtotal: number;
  discount: number;
  /*
  grossPriceBeforeDiscount: number;
  grossPriceAfterDiscount: number;
  netPriceBeforeDiscount: number;
  netPriceAfterDiscount: number;
  grossValueBeforeDiscount: number;
  grossValueAfterDiscount: number;
  netValueBeforeDiscount: number;
  netValueAfterDiscount: number;
*/
  netCatalogPrice: number;
  netCatalogValue: number;
  grossCatalogPrice: number;
  grossCatalogValue: number;
  netUserPrice: number;
  netUserValue: number;
  grossUserPrice: number;
  grossUserValue: number;
  taxPercent: number;
  taxValue: number;
  usersSpecialOfferReward?: IUsersSpecialOfferReward;
  createdAt: Date;
  updatedAt: Date;
}

export class IVoucher {
  type: string;
  value: number;
  code: string
}

export interface IApiOrder {
  id: number;
  externalOrderId: string;
  user: IUser;
  placingUser: IUser;
  currency: any;
  details: IOrderDetails;
  documents: any;
  numbers: any;
  subiektStatus: SubiektStatus;
  status: OrderStatus;
  packedWith: IOrder;
  ordersInPackage: IOrder[];
  vouchers: IVoucher[];
  history: IOrderHistoryEvent[];
  placedAt: Date;
  products: IApiOrderProduct[];
  deliveryData: any;
  paymentData: any;
  virtualWalletTransaction: any;
  specialOffers: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOrderNumbers {
  id: number;
  netTotal: number;
  discount: number;
  codTotal: number;
  tax: number;
  taxPercent: number;
  standardProductsCatalogValue: number;
  standardProductsUserValue: number;
  marketingProductsCatalogValue: number;
  marketingProductsUserValue: number;
  marketingAndStandardProductsCatalogValue: number;
  marketingAndStandardProductsUserValue: number;
  rewardsCatalogValue: number;
  rewardsUserValue: number;
  allProductsCatalogValue: number;
  allProductsUserValue: number;
  maxVirtualWalletDiscount: number;
  payment: number;
  shippingCost: number;
  total: number;
  virtualWalletDiscount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOrder {
  id: number;
  total: number;
  codTotal: number;
  packedWith?: IOrder;
  ordersInPackage?: IOrder[];
  user: IUser;
  currency: string;
  subiektStatus: string;
  details: IOrderDetails;
  status: OrderStatus;
  paymentData: IPaymentData;
  deliveryData: IDeliveryData;
  externalOrderId: string;
  products: IOrderProduct[];
  documents: any;
  numbers: IOrderNumbers;
  history: IOrderHistoryEvent[];
  placedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface IOrderHistoryEvent {
  event: string;
  createdAt: Date | string;
}

export interface OrderDetailsState {
  orderDetails: IOrderDetails;
}

const initialState: OrderDetailsState = {
  orderDetails: {
    id: null,
    buyer: {},
    paymentDocument: PaymentDocument.RECEIPT,
    firstName: '',
    lastName: '',
    companyName: '',
    companyTaxNumber: '',
    address_1: '',
    address_2: '',
    address_3: '',
    postalCode: '',
    city: '',
    shipToDifferentAddress: false,
    shippingFirstName: '',
    shippingLastName: '',
    shippingAddress_1: '',
    shippingAddress_2: '',
    shippingAddress_3: '',
    shippingPostalCode: '',
    shippingCity: '',
    saveShippingAddress: false,
    shippingType: null,
    //ShippingType.DPD,
    deliveryNotes: '',
    email: '',
    phone: '',
    paymentType: PaymentType.P24,
    clientNotes: '',
    walletDiscount: 0,
    userAcceptedRules: false,
    userAcceptedMarketingConsents: false,
    userAcceptedCommercialInfo: false,
  },
};

export const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    updateOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    updateWalletDiscount: (state, action) => {
      state.orderDetails.walletDiscount = action.payload;
    },
    setBuyerInOrderDetails: (state, action) => {
      const buyer = action.payload;

      state.orderDetails.buyer = buyer;
      state.orderDetails.email = buyer.email;
      state.orderDetails.phone = buyer.phone;
      state.orderDetails.firstName = buyer.firstName;
      state.orderDetails.lastName = buyer.lastName;
      state.orderDetails.companyName = buyer.companyName;
      state.orderDetails.companyTaxNumber = buyer.companyTaxNumber;
      state.orderDetails.address_1 = buyer.address_1;
      state.orderDetails.address_2 = buyer.address_2;
      state.orderDetails.address_3 = buyer.address_3;
      state.orderDetails.postalCode = buyer.postalCode;
      state.orderDetails.city = buyer.city;
      state.orderDetails.shipToDifferentAddress = buyer.shipToDifferentAddress;
      state.orderDetails.shippingAddress_1 = buyer.shippingAddress_1;
      state.orderDetails.shippingAddress_2 = buyer.shippingAddress_2;
      state.orderDetails.shippingAddress_3 = buyer.shippingAddress_3;
      state.orderDetails.shippingPostalCode = buyer.shippingPostalCode;
      state.orderDetails.shippingCity = buyer.shippingCity;
    },
    clearOrderDetails: (state) => {
      state.orderDetails = initialState.orderDetails;
      clearLocalStorage('orderDetails');
    },
  },
});

export const orderDetailsReducer = orderDetailsSlice.reducer;

export const {
  updateOrderDetails,
  setBuyerInOrderDetails,
  clearOrderDetails,
  updateWalletDiscount,
} = orderDetailsSlice.actions;

export const orderDetailsSelector = (state: AppState) => state.orderDetailsSlice;
