import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'app/redux/store';
import { baseBackendUrl } from 'utils/config';
import {Paginated} from "../types/paginatedQuery";
import {IFilter} from "../types/filters";
export const VoucherApi = createApi({
  reducerPath: 'vouchers',
  tagTypes: ['Vouchers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseBackendUrl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    all: builder.query<void, void>({
      query: () => `vouchers/all`
    }),
    my: builder.query({
      query: () => `vouchers/my`
    }),
    getFiltered: builder.query<Paginated<any>, IFilter>({
      query: (filters) =>
        `vouchers/all?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`
    }),
    activate: builder.query<any, string>({
      query: (code) =>
        `vouchers/activate/${code}`
    }),
    generate: builder.mutation({
      query(body) {
        return {
          url: `vouchers`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Vouchers']
    }),
    generateReusable: builder.mutation({
      query(body) {
        return {
          url: `vouchers/reusable`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Vouchers']
    }),
    assignToUser: builder.mutation({
      query(data) {
        return {
          url: `vouchers/assign-to-user/${data.voucher}/${data.user}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Vouchers']
    }),
    delete: builder.mutation({
      query(id) {
        return {
          url: `vouchers/${id}`,
          method: 'DELETE',
        };
      },
    })
  }),
});

export default VoucherApi;
