import { UserRole } from 'features/User/UserSlice';
import { IronSessionOptions } from 'iron-session';

export const ironOptions: IronSessionOptions = {
  cookieName: 'session',
  password: process.env.SECRET_COOKIE,
  cookieOptions: {
    secure: false,
  },
};
declare module 'iron-session' {
  export interface IronSessionData {
    user?: {
      id: number | string;
      username: string;
      sub: number;
      name: string;
      lastname: string;
      role: UserRole;
      expires: number;
      token: string;
      refreshToken?: string;
    };
  }
}
