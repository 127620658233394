import { css } from '@emotion/react';
import theme from 'styles/theme';

const globalStyles = css`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    color: ${theme.customColors.colorsSecondaryBrand};
    height: 100%;
    min-height: 100%;
  }

  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  body {
    background-color: ${theme.customColors.shadesPrimaryBrand10};
    margin: 0;
  }

  #navbar {
    .image-holder {
      max-width: 60px;

      @media screen and (min-width: ${theme.breakpoints.values.md}px) {
        max-width: 80px;
      }
    }
  }

  .menu-item--text {
    max-width: 100%;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ::selection {
    background: #8fc4f234;
  }

  main {
    min-height: 100vh;
    display: flex;
    overflow-x: hidden;
  }

  .mobile-page {
    &__content {
      main {
        padding-top: 2rem;
      }
    }
  }

  main.page-without-sidemenu,
  .navbar--without-sidemenu {
    padding-left: ${theme.paddings.sidePadding};
    padding-right: ${theme.paddings.sidePadding};

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-left: ${theme.paddings.sidePadding900};
      padding-right: ${theme.paddings.sidePadding900};
    }

    @media screen and (min-width: 1280px) {
      padding-left: ${theme.paddings.sidePadding1200};
      padding-right: ${theme.paddings.sidePadding1200};
    }

    @media screen and (min-width: 1536px) {
      padding-left: ${theme.paddings.sidePadding1536};
      padding-right: ${theme.paddings.sidePadding1536};
    }
  }

  main.page-without-sidemenu {
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      padding-top: ${theme.spacings.l};
    }
  }

  a {
    color: ${theme.customColors.colorsInfo};
    text-decoration: none;
  }

  button {
    font-family: inherit;
  }

  footer {
    padding: 1rem 0;
    row-gap: 1rem;
    width: 100%;
    border-top-left-radius: ${theme.borderRadius.m};
    border-top-right-radius: ${theme.borderRadius.m};

    .footer__bottom {
      display: flex;
      justify-content: center;
      column-gap: 2rem;
    }
  }

  /* Utilities */

  .dnone,
  .hidden {
    display: none !important;
  }

  .flex {
    display: flex;
  }

  .block {
    display: block;
  }

  .row {
    flex-flow: row;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .flex-row {
    flex-flow: row;
  }

  .flex-col {
    flex-flow: column;
  }

  .flex-drow-mcol {
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      flex-flow: row;
    }
  }

  .flex-grow-full {
    flex: 1 1 100%;
  }

  .items-center {
    align-items: center;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-start {
    align-items: start;
  }

  .items-end {
    align-items: end;
  }

  .content-start {
    justify-content: start;
  }

  .content-between {
    justify-content: space-between;
  }

  .content-center {
    justify-content: center;
  }

  .content-end {
    justify-content: flex-end;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .absolute-item {
    &__holder {
      position: relative;
    }
  }

  .full-h {
    height: 100%;
    min-height: 80vh;
  }

  .full-w {
    width: 100%;
  }

  .m {
    &--auto {
      margin: auto;
    }
  }

  .mr {
    &--75 {
      margin-right: 0.75rem;
    }

    &--1 {
      margin-right: 1rem;
    }
    &--2 {
      margin-right: 2rem;
    }
  }

  .ml {
    &--75 {
      margin-left: 0.75rem;
    }

    &--1 {
      margin-left: 1rem;
    }
    &--2 {
      margin-left: 2rem;
    }
  }

  .mt {
    &--1 {
      margin-top: 1rem;
    }
    &--2 {
      margin-top: 2rem;
    }
    &--3 {
      margin-top: 2rem;
    }
    &--4 {
      margin-top: 4rem;
    }
    &--6 {
      margin-top: 6rem;
    }
    &--8 {
      margin-top: 8rem;
    }
  }

  .mb {
    &--05 {
      margin-bottom: 0.5rem;
    }

    &--1 {
      margin-bottom: 1rem;
    }
    &--2 {
      margin-bottom: 2rem;
    }
    &--3 {
      margin-bottom: 2rem;
    }
    &--4 {
      margin-bottom: 4rem;
    }
    &--6 {
      margin-bottom: 6rem;
    }
    &--8 {
      margin-bottom: 8rem;
    }
  }

  .overflow {
    &--hidden {
      overflow: hidden;
    }

    &--scroll-y {
      overflow-y: scroll;
    }
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .box-shadow {
    &--standard {
      box-shadow: ${theme.boxShadows.standard};
    }
  }

  .image-holder {
    display: flex;
  }

  .bg-image {
    z-index: ${theme.zIndexes.zIndexBehind};

    &__wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: ${theme.zIndexes.zIndexBehind};
    }
  }

  .box-shadow {
    &--standard {
      box-shadow: ${theme.boxShadows.standard};
    }
  }

  .overlay {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &--primary {
      &::before {
        background-color: ${theme.overlays.overlayImage};
      }
    }
  }

  .border-radius {
    &--standard {
      border-radius: ${theme.borderRadius.m};
    }
  }

  .text-center {
    text-align: center;
  }

  .pointer {
    cursor: pointer;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .specialOffer {
    &__form {
      .MuiInputAdornment-root {
        margin-left: 8px;
      }
    }
  }

  .custom-increment-buttons {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .box-decoration--left {
    border-left-width: 8px;
    border-left-style: solid;
    padding-left: 1rem;
  }

  .box-decoration--info {
    border-color: ${theme.palette.primary.dark};
  }

  .box-decoration--success {
    border-color: ${theme.palette.success.dark};
  }

  .product-card__product-name h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .checkout-table__body--desktop tr td:nth-of-type(1) {
    width: 100px;
  }

  .checkout-table__body--desktop tr td:nth-of-type(2) {
    width: 300px;
  }
`;

export default globalStyles;
