import styled from '@emotion/styled';
import { Fade, ListItemText } from '@mui/material';
import Link from 'next/link';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { menuToggleMobile } from './menuSlice';
import {
  IMenuItem,
  IStyledLink,
  StyledListItemButton,
  StyledTooltip,
} from './MenuSingleItem';

export const SubmenuSingleItem: FC<IMenuItem> = ({
  onlyForRoles,
  label,
  href,
  selected,
  disableGutters,
  className,
  drawerState,
  icon,
  onClick,
  viewportMobile,
  userSessionData,
}) => {
  const dispatch = useDispatch();

  const StyledSubmenuLink = styled.a<IStyledLink>`
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: currentColor;

    svg {
      transform: scale(0.8);
    }
  `;

  if (onlyForRoles && !onlyForRoles.includes(userSessionData?.role)) {
    return <></>;
  } else {
    return (
      <div
        className={`menu-item menu-item--submenu flex items-start ${
          className ? className : null
        }`}
      >
        <StyledListItemButton
          disableRipple
          disableGutters={disableGutters}
          selected={selected}
          classes={{ root: 'secondary' }}
        >
          <div
            className="flex items-center full-w relative"
            onClick={() => (viewportMobile ? dispatch(menuToggleMobile()) : '')}
          >
            {href.length > 0 ? (
              <Link href={href} passHref>
                <StyledSubmenuLink onClick={onClick}>
                  <StyledTooltip
                    title={label}
                    arrow
                    placement="right"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 300 }}
                    disableHoverListener={drawerState ? true : false}
                  >
                    {icon}
                  </StyledTooltip>
                  <ListItemText
                    className={'absolute menu-item--text'}
                    sx={{ position: 'absolute', left: '35px' }}
                    disableTypography={true}
                    primary={label}
                  />
                </StyledSubmenuLink>
              </Link>
            ) : (
              <StyledSubmenuLink onClick={onClick}>
                <StyledTooltip
                  title={label}
                  arrow
                  placement="right"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
                  disableHoverListener={drawerState ? true : false}
                >
                  {icon}
                </StyledTooltip>
                <ListItemText
                  className={'absolute'}
                  sx={{ position: 'absolute', left: '35px' }}
                  disableTypography={true}
                  primary={label}
                />
              </StyledSubmenuLink>
            )}
          </div>
        </StyledListItemButton>
      </div>
    );
  }
};

export default SubmenuSingleItem;
