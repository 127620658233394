import {Autocomplete, createFilterOptions, TextField} from '@mui/material';
import React, {ChangeEvent, forwardRef, ReactNode, useEffect} from 'react';
import { displaySingleLabelForOption } from '../utils';

export interface ISingleSelectAutocomplete {
  data: string[] | object[];
  value: object;
  label: string;
  id?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  singleLabelParams?: unknown[] | string;
  inputValueKey?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: ReactNode;
  uniqueKey?: string;
}

export const SingleSelectAutocomplete = forwardRef<
  HTMLInputElement,
  ISingleSelectAutocomplete
>(function SingleSelectAutocomplete(props, ref) {
  const {
    value,
    data,
    label,
    onChange,
    singleLabelParams,
    error,
    disabled,
    helperText,
    uniqueKey,
    ...rest
  } = props;

  const [open, setOpen] = React.useState(false);

  const getData = () => {
    if (!data) {
      return [];
    }

    if (uniqueKey) {
      // @ts-ignore
      return [...new Map(data.map(item => [item[uniqueKey], item])).values()];
    }

    return data;
  }

  return (
    <Autocomplete<any, false, false, true>
      {...rest}
      disablePortal
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_, data) => onChange(data)}
      value={value || null}
      isOptionEqualToValue={(option, value) =>
        singleLabelParams ? option.id === value.id : option === value
      }
      getOptionLabel={(option) => {
        return singleLabelParams
          ? displaySingleLabelForOption(singleLabelParams, option)
          : option;
      }}
      options={getData()}
      disabled={disabled}
      noOptionsText={'Brak wyników'}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          label={label}
          error={error}
          helperText={error ? helperText : null}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
});
