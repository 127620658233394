import {
  Box,
  FormControl,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { SHORT_INPUT_LENGTH } from 'utils/constans';

type PercentageField = {
  field: FieldValues;
  min?: number;
  max?: number;
} & TextFieldProps;

export const PercentageField = forwardRef<HTMLInputElement, PercentageField>(
  function PriceValue(props, ref) {
    const { field, label, min = 1, max = 100, ...rest } = props;

    return (
      <FormControl>
        <Box maxWidth={SHORT_INPUT_LENGTH}>
          <TextField
            {...rest}
            {...field}
            ref={ref}
            type="number"
            label={label}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            inputProps={{ min: min, max: max, step: '1', lang: 'pl-PL' }}
          />
        </Box>
      </FormControl>
    );
  }
);

export default PercentageField;
