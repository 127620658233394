import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/redux/store';
import {baseBackendUrl} from 'utils/config';
import {Paginated} from "../types/paginatedQuery";
import {IFilter} from "../types/filters";
import {IUser} from "../User/UserSlice";

type IBilling = {
    id: number;
    user: IUser;
    amount: number;
    baseAmount: number;
    withActiveProvision: boolean;
    period: string;
    createdAt: Date;
    deletedAt: Date;
}

export const RoleProgressApi = createApi({
    reducerPath: 'roleProgress',
    tagTypes: ['RoleProgress'],
    baseQuery: fetchBaseQuery({
        baseUrl: `${baseBackendUrl}`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).userSlice?.userSessionData?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getFiltered: builder.query<Paginated<IBilling>, IFilter>({
            query: (filters) =>
                `mlmProgress/progress?order=${filters.order}&offset=${filters.offset}${filters.search}&limit=${filters.limit}&sort=${filters.sort}`,
            keepUnusedDataFor: 0,
        }),
    }),
});

export default RoleProgressApi;
