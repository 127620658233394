import { AppDispatch } from 'app/redux/store';
import { authUser, refreshUser, UserSessionData } from 'features/User/UserSlice';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  children: React.ReactNode;
  userSessionData: UserSessionData;
};

const AuthWrapper = (props: Props) => {
  const { children, userSessionData } = props;
  const router = useRouter();

  const isLoggedIn = !!userSessionData;
  const dispatch = useDispatch<AppDispatch>();

  /* Auth Check */
  useEffect(() => {
    const promise = dispatch(authUser());
    return () => {
      promise.abort();
    };
  }, [router, dispatch]);

  /* Refresh Check */
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const timeToExp = userSessionData?.expires - Date.now() / 1000;

    if (timeToExp && timeToExp < 0) {
      const promise = dispatch(refreshUser());

      return () => {
        promise.abort();
      };
    }

    return;
  }, [router, isLoggedIn, userSessionData, dispatch]);

  return <>{children}</>;
};

export default AuthWrapper;
