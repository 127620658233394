import {createSlice} from '@reduxjs/toolkit';
import {AppState} from 'app/redux/store';
import {IProduct} from 'features/Products/productsSlice';
import {
  IReward,
  ISpecialOffer,
  IUserPickData,
  RewardDisplayType,
  rewardsAvailableVariants,
  RewardType,
  UserSpecialOfferRewardType,
} from 'features/SpecialOffers/specialOffersSlice';
import {clearLocalStorage} from 'utils/localStorage';
import {baseBackendUrl} from "../../utils/config";

export interface DiscountString {
  discountString: string;
}

export interface ICartItem {
  product: IProduct;
  quantity: number;
}

export type CalculatedCartItem = ICartItem & {
  catalogPrice: number;
  promoPrice: number;
  userPrice: number;
  catalogValue: number;
  userValue: number;
  catalogPriceStr: string;
  userPriceStr: string;
  catalogValueStr: string;
  userValueStr: string;
};

export type CalculatedCartChosenReward = {
  reward: IReward;
  calculated: any;
};

export type CalculatedCartSpecialOffer = {
  offer: ISpecialOffer;
  chosenRewards: CalculatedCartChosenReward[];
};

export type CalculatedCart = {
  standardProducts: CalculatedCartItem[];
  marketingProducts: CalculatedCartItem[];
  freeProducts: CalculatedCartItem[];
  voucherProducts: CalculatedCartItem[];
  specialOffers: CalculatedCartSpecialOffer[];
  standardProductsCatalogValue: number;
  standardProductsCatalogValueStr: string;
  standardProductsUserValue: number;
  standardProductsUserValueStr: string;
  marketingProductsCatalogValue: number;
  marketingProductsCatalogValueStr: string;
  marketingProductsUserValue: number;
  marketingProductsUserValueStr: string;
  marketingAndStandardProductsCatalogValue: number;
  marketingAndStandardProductsCatalogValueStr: string;
  marketingAndStandardProductsUserValue: number;
  marketingAndStandardProductsUserValueStr: string;
  rewardsCatalogValue: number;
  rewardsCatalogValueStr: string;
  rewardsUserValue: number;
  rewardsUserValueStr: string;
  allProductsCatalogValue: number;
  allProductsCatalogValueStr: string;
  allProductsUserValue: number;
  allProductsUserValueStr: string;
  shippingCost: number;
  shippingCostStr: string;
  total: number;
  totalStr: string;
  totalForConditions: number;
  maxVirtualWalletDiscount: number;
  virtualWalletDiscount: number;
  virtualWalletDiscountStr: string;
  payment: number;
  paymentStr: string;
  discountCode: string;
  discountProductCode: string;
  discountType: string;
  discountValue: number;
  discountProductValue: number;
  discountStringValue: string;
};

export interface CartItemsState extends DiscountString {
  selectedNewUsers: number[];
  cartItems: ICartItem[];
  cartFreeItems: ICartItem[];
  voucherProducts: ICartItem[];
  calculatedCart: CalculatedCart;
  fetchedActiveSpecialOffers?: ISpecialOffer[];
  cartActiveSpecialOffers?: ISpecialOffer[];
  isDiscountStringActive: boolean;
  discountStringValue: number;
  discountFromPreviousMonth: number;
  isCheckedOut: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
  discountProductString: string;
  discountProductValue: number;
  error: Error;
}

export interface ICartItemDTO {
  catalogPrice: number;
  catalogValue: number;
  product: number;
  quantity: number;
  userPrice: number;
  userValue: number;
}

export interface ICartSpecialOfferRewardDTO {
  calculatedData: ICartItemDTO | ICartItemDTO[];
  reward: number;
  userPickData: { qty?: number; product?: number; products?: number[] };
  userSpecialOfferRewardType: UserSpecialOfferRewardType;
  type: RewardDisplayType;
}

export interface ICartSpecialOfferDTO {
  offer: number;
  rewards: ICartSpecialOfferRewardDTO[];
}

export interface ICartTotalsDTO {
  standardProductsCatalogValue: number;
  standardProductsUserValue: number;
  marketingProductsCatalogValue: number;
  marketingProductsUserValue: number;
  marketingAndStandardProductsCatalogValue: number;
  marketingAndStandardProductsUserValue: number;
  rewardsCatalogValue: number;
  rewardsUserValue: number;
  allProductsCatalogValue: number;
  allProductsUserValue: number;
  shippingCost: number;
  virtualWalletDiscount: number;
  maxVirtualWalletDiscount: number;
  total: number;
  payment: number;
}

export interface ICartDTO {
  marketingProducts: ICartItemDTO[];
  freeProducts: ICartItemDTO[];
  voucherProducts: ICartItemDTO[];
  standardProducts: ICartItemDTO[];
  specialOffers: ICartSpecialOfferDTO[];
  totals: ICartTotalsDTO;
}

const initialState: CartItemsState = {
  selectedNewUsers: [],
  cartItems: [],
  cartFreeItems: [],
  voucherProducts: [],
  calculatedCart: null,
  fetchedActiveSpecialOffers: [],
  cartActiveSpecialOffers: [],
  discountString: '',
  discountProductString: '',
  isDiscountStringActive: false,
  discountStringValue: 0,
  discountProductValue: 0,
  discountFromPreviousMonth: 0,
  isCheckedOut: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  error: undefined
};

export const noPickActionRewards: Partial<
  typeof rewardsAvailableVariants[number]['type'][]
> = [
  RewardType.COUPON,
  RewardType.DISCOUNT_ORDER,
  RewardType.DISCOUNT_SHIPPING,
  RewardType.FREE_PRODUCT,
  RewardType.MONTHS_NUMBER,
  RewardType.DAYS_NUMBER,
];

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    addProductToCart: (
      state,
      action: {
        payload: IProduct;
      }
    ) => {
      const itemAlreadyInCart = state.cartItems.find((item) => {
        return item.product.id === action.payload.id;
      });

      if (itemAlreadyInCart) {
        if (action.payload.quantity) {
          itemAlreadyInCart.quantity =
            itemAlreadyInCart.quantity + action.payload.quantity;
        } else {
          itemAlreadyInCart.quantity++;
        }
      } else {
        const {quantity, ...product} = action.payload;
        state.cartItems?.push({
          product: product,
          quantity: quantity || 1,
        });
      }
    },
    addSelectedNewUser: (
      state,
      action: {
        payload: number;
      }
    ) => {
      const user = state.selectedNewUsers.find((item) => {
        return item === action.payload;
      });

      if (!user) {
        state.selectedNewUsers?.push(action.payload);
      }
    },
    addFreeProductToCart: (
      state,
      action: {
        payload: IProduct;
      }
    ) => {
      const itemAlreadyInCart = state.cartFreeItems?.find((item) => {
        return item.product.id === action.payload.id;
      });

      if (itemAlreadyInCart) {
        if (action.payload.quantity) {
          itemAlreadyInCart.quantity = itemAlreadyInCart.quantity + action.payload.quantity;
        } else {
          itemAlreadyInCart.quantity++;
        }
      } else {
        const {quantity, ...product} = action.payload;
        state.cartFreeItems?.push({
          product: product,
          quantity: quantity || 1,
        });
      }
    },
    addVoucherProductToCart: (
      state,
      action: {
        payload: IProduct;
      }
    ) => {
      const itemAlreadyInCart = state.voucherProducts?.find((item) => {
        return item.product.id === action.payload.id;
      });

      if (itemAlreadyInCart) {
        if (action.payload.quantity) {
          itemAlreadyInCart.quantity = itemAlreadyInCart.quantity + action.payload.quantity;
        } else {
          itemAlreadyInCart.quantity++;
        }
      } else {
        const {quantity, ...product} = action.payload;
        state.voucherProducts?.push({
          product: product,
          quantity: quantity || 1,
        });
      }
    },
    setPickedReward: (state, action) => {
      const activeSpecialOfferId = action.payload.activeSpecialOfferId;
      const chosenRewardIndex = action.payload.rewardIndex;
      const userPickData: IUserPickData = action.payload.userPickData;

      state.cartActiveSpecialOffers.map((activeSpecialOffer) => {
        if (activeSpecialOffer.id === activeSpecialOfferId) {
          activeSpecialOffer.rewards[chosenRewardIndex].isChosen = true;
          activeSpecialOffer.rewards[chosenRewardIndex].userPickData = {
            ...userPickData,
          };
        }
      });
    },
    unsetPickedReward: (state, action) => {
      const activeSpecialOfferId = action.payload.activeSpecialOfferId;

      state.cartActiveSpecialOffers.map((activeSpecialOffer) => {
        if (activeSpecialOffer.id === activeSpecialOfferId) {
          activeSpecialOffer.rewards.map((reward) => {
            reward.isChosen = false;
            reward.userPickData = {};
          });
        }
      });
    },
    refreshFetchedActiveSpecialOffers: (state, action) => {
      /* fetchedActiveSpecialOffers array purpose is to save active special offers fetched from backend
       in its original form, so with the next fetch it will be possible to compare them and perform state refresh only when they differ */
      if (action?.payload) {
        state.fetchedActiveSpecialOffers = [...action.payload];
      }
    },
    refreshCartActiveSpecialOffers: (state, action) => {
      const activeSpecialOffers: ISpecialOffer[] = action.payload;
      const cartActiveSpecialOffers: ISpecialOffer[] = [];

      activeSpecialOffers &&
      activeSpecialOffers.map((activeSpecialOffer) => {
        const allRewardTypesInThisSpecialOffer = activeSpecialOffer.rewards.map(
          (reward) => reward.type
        );

        if (allRewardTypesInThisSpecialOffer) {
          activeSpecialOffer = {
            ...activeSpecialOffer,
            allRewardTypesInThisSpecialOffer: [...allRewardTypesInThisSpecialOffer],
          };
        }

        const noPickRewardActionSpecialOffer = noPickActionRewards.some((type) =>
          allRewardTypesInThisSpecialOffer.includes(type)
        );

        if (noPickRewardActionSpecialOffer) {
          activeSpecialOffer.noPickRewardActionSpecialOffer = true;
        } else {
          activeSpecialOffer.noPickRewardActionSpecialOffer = false;
        }

        /* Mark reward as chosen if it should be autopicked */
        const shouldAutoPickReward =
          noPickRewardActionSpecialOffer && activeSpecialOffer.rewards.length === 1;

        if (shouldAutoPickReward) {
          let autoPickedReward = activeSpecialOffer.rewards[0];

          autoPickedReward = {
            ...autoPickedReward,
            isChosen: true,
          };

          activeSpecialOffer = {
            ...activeSpecialOffer,
            rewards: [autoPickedReward],
          };
        }

        cartActiveSpecialOffers.push(activeSpecialOffer);
      });

      state.cartActiveSpecialOffers = [...cartActiveSpecialOffers];
    },
    setQuantity: (state, action) => {
      const item = state.cartItems?.find(
        (item) => item.product.id === action.payload.itemId
      );

      if (item) {
        item.quantity = action.payload.itemQty;
      }
    },
    setDiscountForPreviousMonth: (state, action) => {
      state.discountFromPreviousMonth = action.payload;
    },
    setCalculatedCart: (state, action) => {
      state.calculatedCart = action.payload;
    },
    checkValueCondition: (state) => {
      const value = state.cartItems.reduce(
        (sum, item) => item.product.catalogPrice * item.quantity, 0
      );

      if (value < 40000) {
        state.selectedNewUsers = [];
        state.cartFreeItems = [];
        state.voucherProducts = [];
        state.discountString = '';
        state.isDiscountStringActive = false;
        state.discountStringValue = 0;
        state.discountProductString = '';
        state.discountProductValue = 0;
      }
    },
    removeFromCart: (state, action) => {
      const index = state.cartItems?.findIndex(
        (item) => item.product.id === action.payload
      );
      state.cartItems?.splice(index, 1);
    },
    removeFromFreeCart: (state, action) => {
      const index = state.cartItems?.findIndex(
        (item) => item.product.id === action.payload
      );
      state.cartFreeItems?.splice(index, 1);
    },
    removeFromVoucherCart: (state, action) => {
      const index = state.cartItems?.findIndex(
        (item) => item.product.id === action.payload
      );
      state.voucherProducts?.splice(index, 1);
    },
    removeFromSelectedNewUser: (state, action) => {
      const index = state.selectedNewUsers?.findIndex(
        (item) => item === action.payload
      );
      state.selectedNewUsers?.splice(index, 1);
    },
    setDiscountStringState: (state, action) => {
      state.discountString = action.payload.discountString;
      state.discountStringValue = action.payload.discountStringValue;
    },
    setDiscountProductState: (state, action) => {
      state.discountProductString = action.payload.discountString;
      state.discountProductValue = action.payload.discountStringValue;
    },
    setSelectedNewUser: (state, action) => {
      state.selectedNewUsers = action.payload
    },
    setExceptionError: (state, action) => {
      state.error = action.payload
    },
    clearDiscountString: (state) => {
      state.discountString = '';
      state.isDiscountStringActive = false;
      state.discountStringValue = 0;
    },
    clearDiscountProductState: (state) => {
      state.discountProductString = '';
      state.discountProductValue = 0;
      state.voucherProducts = [];
    },
    clearVoucherProducts: (state) => {
      state.voucherProducts = [];
    },
    clearSelectedNewUsers: (state) => {
      state.selectedNewUsers = [];
      state.cartFreeItems = [];
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.discountString = null;
      state.discountProductString = null;
      state.isDiscountStringActive = false;
      state.discountStringValue = 0;
      state.discountProductValue = 0;
      state.cartFreeItems = [];
      state.voucherProducts = [];
      state.selectedNewUsers = [];
      state.cartActiveSpecialOffers = [];
      state.fetchedActiveSpecialOffers = [];
      clearLocalStorage('cart');
    },
  },
});

export const cartReducer = cartSlice.reducer;

export const {
  addProductToCart,
  setQuantity,
  removeFromCart,
  removeFromFreeCart,
  setDiscountStringState,
  clearDiscountString,
  clearCart,
  setCalculatedCart,
  setPickedReward,
  unsetPickedReward,
  refreshFetchedActiveSpecialOffers,
  refreshCartActiveSpecialOffers,
  setDiscountForPreviousMonth,
  addFreeProductToCart,
  removeFromSelectedNewUser,
  removeFromVoucherCart,
  addSelectedNewUser,
  setDiscountProductState,
  clearDiscountProductState,
  addVoucherProductToCart,
  setSelectedNewUser,
  setExceptionError,
  clearSelectedNewUsers,
  clearVoucherProducts,
  checkValueCondition,
} = cartSlice.actions;

export const cartSelector = (state: AppState) => state.cartSlice;
