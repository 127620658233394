import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { FieldValues } from 'react-hook-form';

type QtyField = {
  field: FieldValues;
  min?: number;
  max?: number;
} & TextFieldProps;

export const QtyField = forwardRef<HTMLInputElement, QtyField>(function PriceValue(
  props,
  ref
) {
  const { field, label, min = 1, max = 9999, ...rest } = props;

  return (
    <TextField
      {...rest}
      {...field}
      ref={ref}
      type="number"
      label={label}
      onKeyDown={(event) => {
        if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
          event.preventDefault();
        }
      }}
      inputProps={{ min: min, max: max, step: '1', lang: 'pl-PL' }}
    />
  );
});

export default QtyField;
