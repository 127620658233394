import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import PercentageField from 'components/PercentageField';
import PriceField from 'components/PriceField';
import QtyField from 'components/QtyField';

import { ReactDateTimePicker } from 'components/ReactDateTimePicker';
import { CouponValueType } from 'features/SpecialOffers/specialOffersSlice';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import theme from 'styles/theme';
import { SHORT_INPUT_LENGTH } from 'utils/constans';

export default function Coupon({ index, chosenReward }) {
  const { control, unregister, formState, clearErrors, setValue } = useFormContext();

  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.value`);
      unregister(`rewards.${index}.code`);
      unregister(`rewards.${index}.expiredAt`);
    };
  }, [index, unregister]);

  const refreshVariant = () => {
    clearErrors(`rewards.${index}.value`);
    unregister(`rewards.${index}.value`);
  };

  return (
    <>
      <Controller
        name={`rewards.${index}.valueType`}
        control={control}
        defaultValue={`${CouponValueType.VALUE}`}
        render={({ field }) => (
          <FormControl onChange={() => refreshVariant()}>
            <RadioGroup aria-labelledby={field.name} {...field}>
              <Grid container direction="row">
                <FormControlLabel
                  value={`${CouponValueType.VALUE}`}
                  control={<Radio />}
                  label="kwotowy"
                />
                <FormControlLabel
                  value={`${CouponValueType.PRODUCTS}`}
                  control={<Radio />}
                  label="produktowy"
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={chosenReward?.[index]?.valueType === 'value' ? 25 : 50}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PriceField
                  field={field}
                  label={'Wartość'}
                  error={errors?.rewards?.[index]?.value}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.value?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.period.to`}
        control={control}
        defaultValue={new Date('2099-03-25')}
        render={({ field }) => {
          return (
            <FormControl
              error={!!errors?.rewards?.[index]?.expiredAt}
              focused={!!errors?.rewards?.[index]?.expiredAt}
              className={!!errors?.rewards?.[index]?.expiredAt ? 'error' : null}
            >
              <Box maxWidth={SHORT_INPUT_LENGTH}>
                <Typography mb={theme.spacings.xs4}>
                  Ważność kuponu:
                </Typography>
                <ReactDateTimePicker {...field} />
              </Box>
              <FormHelperText>
                <>
                  {
                    //@ts-ignore
                    errors?.rewards?.[index]?.expiredAt
                  }
                </>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
