import { Box, FormControl, FormHelperText } from '@mui/material';
import { SingleSelectAutocomplete } from 'components/selectsAutocomplete/SingleSelectAutocomplete';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CategoriesSlice from 'features/Categories/catogoriesSlice';
import PriceField from 'components/PriceField';
import QtyField from 'components/QtyField';

export default function ProductsFromCategory({ index }) {
  const { control, unregister, formState } = useFormContext();

  const { useGetAllCategoriesQuery } = CategoriesSlice;
  const { data: allCategories } = useGetAllCategoriesQuery();

  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.productsCategory`);
      unregister(`rewards.${index}.maxQty`);
      unregister(`rewards.${index}.value`);
    };
  }, [index, unregister]);

  return (
    <>
      <Controller
        name={`rewards.${index}.productsCategory`}
        control={control}
        render={({ field }) => {
          return (
            <FormControl error={!!errors?.rewards?.[index]?.productsCategory}>
              <SingleSelectAutocomplete
                {...field}
                label={'Kategoria produktów'}
                data={(allCategories && [...allCategories.data]) || []}
                singleLabelParams={['name']}
                error={!!errors?.rewards?.[index]?.productsCategory}
              />
              <FormHelperText>
                <>{errors?.rewards?.[index]?.productsCategory?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.maxQty`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <QtyField
                  field={field}
                  min={0}
                  max={9999}
                  label="Liczba max"
                  error={errors?.rewards?.[index]?.maxQty}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.maxQty?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />

      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={49}
        render={({ field }) => {
          return (
            <FormControl>
              <Box>
                <PriceField
                  field={field}
                  label="Cena produktów"
                  error={errors?.rewards?.[index]?.value}
                />
              </Box>
              <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                <>{errors?.rewards?.[index]?.value?.message}</>
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
}
