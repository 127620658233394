import { Grid } from '@mui/material';
import React, { ReactComponentElement } from 'react';
import theme from 'styles/theme';

export interface IContainerLightBLueBg {
  children: ReactComponentElement<any, any> | ReactComponentElement<any, any>[];
}

export default function ContainerLightBlueBg(props: IContainerLightBLueBg) {
  const { children } = props;

  return (
    <Grid
      container
      item
      flexDirection={'column'}
      mb={theme.spacings.xs2}
      sx={{
        backgroundColor: theme.customColors.shadesPrimaryBrand10,
        border: theme.border.table,
        borderRadius: theme.borderRadius.m,
        padding: '40px',
      }}
    >
      {children}
    </Grid>
  );
}
