import { SvgIconProps } from '@mui/material';
import React from 'react';

const DiscountIcon = React.forwardRef<HTMLElement & SVGElement, SvgIconProps>(
  function DiscountIcon(props, ref) {
    return (
      <svg
        {...props}
        style={{
          userSelect: `none`,
          width: `1.875rem`,
          height: ` 1.875rem`,
          display: `inline-block`,
          fill: `currentColor`,
          flexShrink: 0,
          transition: `fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
          fontSize: `1.5rem`,
        }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill={props.fill || 'currentColor'}
        xmlns="http://www.w3.org/2000/svg">
        <path d="M6.33348 4.97286C6.88681 4.97286 7.33348 5.41953 7.33348 5.97286C7.33348 6.5262 6.88681 6.97286 6.33348 6.97286C5.78014 6.97286 5.33348 6.5262 5.33348 5.97286C5.33348 5.41953 5.78014 4.97286 6.33348 4.97286ZM9.66681 9.63953C10.2201 9.63953 10.6668 10.0862 10.6668 10.6395C10.6668 11.1929 10.2201 11.6395 9.66681 11.6395C9.11348 11.6395 8.66681 11.1929 8.66681 10.6395C8.66681 10.0862 9.11348 9.63953 9.66681 9.63953ZM5.60681 11.6395L4.66681 10.6995L10.3935 4.97286L11.3335 5.91286L5.60681 11.6395ZM12.4335 2.2062L12.8401 4.77953L15.1801 5.97286L14.0001 8.3062L15.1868 10.6395L12.8268 11.8329L12.4201 14.4062L9.82681 13.9995L7.98014 15.8462L6.12681 13.9729L3.55348 14.3995L3.14014 11.8062L0.813477 10.6195L2.00014 8.2862L0.820143 5.97286L3.16014 4.7662L3.56681 2.21953L6.14681 2.63953L8.00014 0.769531L9.84014 2.61286L12.4335 2.2062ZM13.4001 6.55286L11.6668 5.63953L11.3335 3.71286L9.40014 3.99286L8.00014 2.63953L6.60014 3.99286L4.66681 3.71286L4.33348 5.63953L2.60014 6.55286L3.46681 8.3062L2.60014 10.0595L4.33348 10.9729L4.66681 12.8995L6.60014 12.6195L8.00014 13.9729L9.40014 12.6195L11.3335 12.8995L11.6668 10.9729L13.4001 10.0595L12.5335 8.3062L13.4001 6.55286V6.55286Z" />
      </svg>
    );
  }
);

export default DiscountIcon;
