import PercentageField from 'components/PercentageField';
import PriceField from 'components/PriceField';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DiscountType } from 'features/SpecialOffers/specialOffersSlice';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function DiscountShipping({ index, chosenReward }) {
  const { control, unregister, clearErrors, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    return () => {
      unregister(`rewards.${index}.valueType`);
      unregister(`rewards.${index}.value`);
    };
  }, [index, unregister]);

  const refreshVariant = () => {
    clearErrors(`rewards.${index}.value`);
    unregister(`rewards.${index}.value`);
  };

  return (
    <>
      <Controller
        name={`rewards.${index}.valueType`}
        control={control}
        defaultValue={`${DiscountType.DISCOUNT}`}
        render={({ field }) => (
          <FormControl onChange={() => refreshVariant()}>
            <RadioGroup aria-labelledby={field.name} {...field}>
              <Grid container direction="row">
                <FormControlLabel
                  value={`${DiscountType.PROMO}`}
                  control={<Radio />}
                  label="promocyjny koszt wysyłki"
                />
                <FormControlLabel
                  value={`${DiscountType.DISCOUNT}`}
                  control={<Radio />}
                  label="rabat"
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        name={`rewards.${index}.value`}
        control={control}
        defaultValue={1}
        render={({ field }) => {
          if (chosenReward?.[index]?.valueType === DiscountType.DISCOUNT) {
            return (
              <FormControl>
                <Box>
                  <PercentageField
                    field={field}
                    label={'Wartość'}
                    error={errors?.rewards?.[index]?.value}
                  />
                </Box>
                <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                  <>{errors?.rewards?.[index]?.value?.message}</>
                </FormHelperText>
              </FormControl>
            );
          }

          if (chosenReward?.[index]?.valueType === DiscountType.PROMO) {
            return (
              <FormControl>
                <Box>
                  <PriceField
                    field={field}
                    label={'Wartość'}
                    error={errors?.rewards?.[index]?.value}
                  />
                </Box>
                <FormHelperText sx={{ margin: '3px 0 0 0' }}>
                  <>{errors?.rewards?.[index]?.value?.message}</>
                </FormHelperText>
              </FormControl>
            );
          }
        }}
      />
    </>
  );
}
