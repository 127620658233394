const defaultSEOconfig = {
  title: 'JUST Polska',
  description:
    'JUST cieszy się uznaniem na całym świecie. Oferuje swoim Klientom najwyższej jakości kosmetyki prosto z natury, które mają zapewnić dobre samopoczucie, piękno i...',
  canonical: 'https://crm.justpolska.pl/',
  noindex: false,
  openGraph: {
    url: 'https://crm.justpolska.pl/',
    title: 'JUST Polska',
    description:
      'JUST cieszy się uznaniem na całym świecie. Oferuje swoim Klientom najwyższej jakości kosmetyki prosto z natury, które mają zapewnić dobre samopoczucie, piękno i...',
    images: [
      {
        url: '',
        width: 400,
        height: 400,
        alt: 'JUST Polska Logo.',
      },
    ],
    site_name: 'JUST Polska',
  },
};

export default defaultSEOconfig;
