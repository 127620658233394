import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Paginated } from 'features/types/paginatedQuery';
import { baseBackendUrl } from 'utils/config';
import { rolesColors } from './rolesColors';
import { RootState } from 'app/redux/store';

export interface IMlmRole {
  id: number;
  name: string;
  level: number;
  discount: number;
  additionalDiscount: number;
  label: keyof typeof rolesColors;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

const rolesSlice = createApi({
  reducerPath: 'roles',
  baseQuery: fetchBaseQuery({
    baseUrl: baseBackendUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).userSlice?.userSessionData?.token;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ['Role'],
  endpoints: (builder) => ({
    getAllRoles: builder.query<Paginated<IMlmRole>, void>({
      query: () => 'mlmroles',
      providesTags: (result) =>
        result?.totalCount > 0
          ? result?.data.map(({ id }) => ({ type: 'Role', id }))
          : [],
    }),
    getRole: builder.query<IMlmRole, number>({
      query: (id) => `mlmroles/${id}`,
      providesTags: (result) => (result ? [{ type: 'Role', id: result.id }] : []),
    }),
    addRole: builder.mutation<IMlmRole, IMlmRole>({
      query(body) {
        return {
          url: 'mlmroles',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Role'],
    }),
    editRole: builder.mutation<IMlmRole, Partial<IMlmRole>>({
      query({ id, ...rest }) {
        return {
          url: `mlmroles/${id}`,
          method: 'PUT',
          body: rest,
        };
      },
      invalidatesTags: ['Role'],
    }),
    deleteOneRole: builder.mutation<IMlmRole, number>({
      query(id) {
        return {
          url: `mlmroles/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Role'],
    }),
  }),
});

export default rolesSlice;
