export const TAX_RATE = 0.23;
export const PRODUCTS_PER_PAGE = 60;
export const MENU_ITEM_NAME_CHAR_LIMIT = 24;
export const MIN_ROW_HEIGHT = 60;

export const PRODUCT_NAME_CHAR_LIMIT = 100;
export const DESCRIPTION_CHAR_LIMIT = 250;
export const PRODUCT_SKU_CHAR_LIMIT = 20;
export const CATEGORY_NAME_CHAR_LIMIT = 100;
export const CATEGORY_HEADER_CHAR_LIMIT = 250;
export const PRODUCT_NAME_FOR_ALT_CHAR_LIMIT = 100;
export const FILE_NAME_CHAR_LIMIT = 75;

export const SHORT_INPUT_LENGTH = 280;

export const CSV_ENCODING = 'windows-1250';
