import { createSlice } from '@reduxjs/toolkit';

import type { AppState } from 'app/redux/store';

export interface IElementObject {
  id: string;
  width: number;
  height: number;
}

export interface DomElementsSlice {
  elementsMeasured: IElementObject[];
  //   status?: 'idle' | 'loading' | 'failed';
}

const initialState: DomElementsSlice = {
  elementsMeasured: [],
  //   status: 'idle',
};

export const domElementsSlice = createSlice({
  name: 'domElementsSlice',
  initialState,
  reducers: {
    saveElementDataInState: (state, action) => {
      const { id, width, height } = action.payload;

      const itemExists = state.elementsMeasured.find((item) => item.id === id);
      if (itemExists) {
        itemExists.width = width;
        itemExists.height = height;
      } else {
        state.elementsMeasured.push({ ...action.payload });
      }
    },
  },
});

export const { saveElementDataInState } = domElementsSlice.actions;

export const domElementsSelector = (state: AppState) => state.domElementsSlice;

export default domElementsSlice.reducer;
